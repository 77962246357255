import { FunctionComponent, useState } from "react";
import { observer } from "mobx-react"
import { Box, Checkbox, FormControlLabel, InputLabel, Slider, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import AreaModelEntity from "../models/layout/AreaModelEntity";
import { Vector3 } from "@babylonjs/core";
import QuaternionModel from "../models/layout/QuaternionModel";



const AreaModelProperties: FunctionComponent<{ areaModel: AreaModelEntity }> = observer(({ areaModel }) => {

  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <DoorSlidingOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Area Model Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
        <Typography marginBottom={2}>{areaModel.modelType} Model</Typography>
          <Box component="div">
              {areaModel.modelType !== "Lidar" &&
              <FormControlLabel control={<Checkbox checked={areaModel.usePoseFromLidar}
                  onChange={(e, v: boolean) => {areaModel.setUsePoseFromLidar(v as boolean)}} />}
                  label="Use Lidar Scan Position"/>
                  }
              {areaModel.modelType !== "Lidar" && !areaModel.usePoseFromLidar &&
                <Slider
                  size="small"
                  value={areaModel.scale}
                  min={-50}
                  max={50}
                  step={0.001}
                  valueLabelDisplay="auto"
                  onChange={(e, v) => {areaModel.setScale(v as number)}}
                />
              }
              {(!areaModel.usePoseFromLidar || areaModel.modelType === "Lidar") &&
              <>
                <TextField
                  label="Position X" margin="normal"
                  inputProps={{step: 0.01, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
                  value={areaModel.pose.position.x}
                  onChange={(e) => {areaModel.pose.position.setX(parseFloat(e.target.value))}}
                />
                <TextField
                  label="Position Y" margin="normal"
                  inputProps={{step: 0.01, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
                  value={areaModel.pose.position.y}
                  onChange={(e) => {areaModel.pose.position.setY(parseFloat(e.target.value))}}
                />
                <TextField
                  label="Position Z" margin="normal"
                  inputProps={{step: 0.01, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
                  value={areaModel.pose.position.z}
                  onChange={(e) => {areaModel.pose.position.setZ(parseFloat(e.target.value))}}
                />
                  <TextField
                  label="Rotation X" margin="normal"
                  inputProps={{step: .25, min: -360, max: 360}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
                  value={areaModel.pose.orientation.asEulerAngles.x}
                  onChange={(e) => {
                    let eulerAngles = areaModel.pose.orientation.asEulerAngles;
                    eulerAngles.set(parseFloat(e.target.value), eulerAngles.y, eulerAngles.z);
                    areaModel.pose.setOrientation(QuaternionModel.fromEulerAngles(eulerAngles));
                  }}
                />
                <TextField
                  label="Rotation Y" margin="normal"
                  inputProps={{step: .25, min: -360, max: 360}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
                  value={areaModel.pose.orientation.asEulerAngles.y}
                  onChange={(e) => {
                    let eulerAngles = areaModel.pose.orientation.asEulerAngles;
                    eulerAngles.set(eulerAngles.x, parseFloat(e.target.value), eulerAngles.z);
                    areaModel.pose.setOrientation(QuaternionModel.fromEulerAngles(eulerAngles));
                  }}
                />
                <TextField
                  label="Rotation Z" margin="normal"
                  inputProps={{step: .25, min: -360, max: 360}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
                  value={areaModel.pose.orientation.asEulerAngles.z}
                  onChange={(e) => {
                    let eulerAngles = areaModel.pose.orientation.asEulerAngles;
                    eulerAngles.set(eulerAngles.x, eulerAngles.y, parseFloat(e.target.value));
                    areaModel.pose.setOrientation(QuaternionModel.fromEulerAngles(eulerAngles));
                  }}
                />
              </>}
              <Typography variant="caption">
                Area Model Cutoff Height
              </Typography>
              <Slider
                size="small"
                value={areaModel.clipHeight}
                min={0}
                max={5}
                step={0.01}
                valueLabelDisplay="auto"
                onChange={(e, v) => {areaModel.setClipHeight(v as number)}}
              />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>

  )
})

export default AreaModelProperties;