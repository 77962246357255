export enum RecordingState {
    Stopped,
    Starting,
    Recording,
    RecordingWithTracking,
    Stopping,
    Error,
}

export interface HubStatusFragmentAgeOutPolicy {
    minimumFreeBytes: number;
    maximumAgeTimeSpan: number;
}

export interface HubStatusCamera {
    cameraId: string;
    name: string;
    videoFPS: number | null;
    inferenceFPS: number | null;
}

export interface HubStatus {
    runningSince: string;
    recordingState: RecordingState;
    fragmentAgeOutPolicy: HubStatusFragmentAgeOutPolicy;
    versionsUrl: string;
    version: string;
    rootApiUrl: string;
    cameras: HubStatusCamera[];
}

export class HubStatusResponse {
    public id: string;
    public time: string;
    public status: HubStatus;

    constructor(id: string, data: any) {
        this.id = id;
        this.time = data.time;
        this.status = data.status;
    }

    public getRecordingState(): string {
        switch (this.status.recordingState) {
            case RecordingState.Starting: return "Starting";
            case RecordingState.Recording: return "Recording (No Tracking)";
            case RecordingState.RecordingWithTracking: return "Recording";
            case RecordingState.Stopping: return "Stopping";
            case RecordingState.Stopped: return "Stopped";
            case RecordingState.Error: return "Error";
            default: return "Unknown";
        }
    }

    private getAverageFPS(property: 'inferenceFPS' | 'videoFPS'): number | null {
        const fpsValues = this.status.cameras
            .map(camera => camera[property])
            .filter(fps => fps != null);
        if (fpsValues.length === 0) {
            return null;
        }

        const totalFPS = fpsValues.reduce((sum, fps) => sum + fps, 0);
        return totalFPS / fpsValues.length;
    }

    public getHubStatusAverageInferenceFPS(): number | null {
        return this.getAverageFPS('inferenceFPS');
    }

    public getHubStatusAverageVideoFPS(): number | null {
        return this.getAverageFPS('videoFPS');
    }

    public getHubStatusHealth(): string {    
        switch (this.status.recordingState) {
            case RecordingState.Starting:
            case RecordingState.Recording:
                return "yellow";
            case RecordingState.Stopping:
            case RecordingState.Stopped:
            case RecordingState.Error:
                return "red";
            case RecordingState.RecordingWithTracking:
                break;
            default:
                return "red";
        }

        const now = new Date();
        const lastUpdate = new Date(this.time);
        const differenceInMinutes = (now.getTime() - lastUpdate.getTime()) / (1000 * 60);
    
        const averageFPS = this.getHubStatusAverageVideoFPS() ?? 0;
    
        if (differenceInMinutes < 10 && averageFPS > 10)
            return "green";
        else if (differenceInMinutes < 120)
            return "yellow";        
        else
            return "red";
    }    
}


