import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { ILoadedModel } from 'react-babylonjs'
import '@babylonjs/loaders'
import "@babylonjs/loaders/glTF";
import "../components-3d/SplatFileLoader";
import { Color3, Plane } from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import ModelWithProgress from "./ModelWithProgress";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { ModelType } from "../models/ModelType";
import SiteNavigator from "../models/SiteNavigator";
import { Console } from "console";
import AreaModelEntity from "../models/layout/AreaModelEntity";

const SiteModelLayer: FunctionComponent<{siteNavigator: SiteNavigator, clipHeight: number}> = observer(({siteNavigator, clipHeight}) => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const [modelUrlRoot, setModelUrlRoot] = useState<any>(null);
  const [modelUrlFilename, setModelUrlFilename] = useState<any>(null);
  const [loadedModel, setLoadedModel] = useState<ILoadedModel | undefined>(undefined);
  const [version, setVersion] = useState(0);

  // we use an effect to load a new model anytime the selectedSite
  useEffect(() => {
    setModelUrlFilename(null);
    setModelUrlRoot(null);
    setVersion(version + 1); // this version helps us work around the fact that loading a new mesh doesn't clear out the old one
    if (siteNavigator.currentScan) {
      loadScanModel(siteNavigator.site.account.accountId, siteNavigator.site.siteId, siteNavigator.currentScan.scanId, siteNavigator.currentModelType);
    }
  }, [siteNavigator.currentScan, siteNavigator.currentModelType]);

  useEffect(() => {
    if (loadedModel) {
      clipModel(loadedModel);
    }
  }, [clipHeight]);

  async function loadScanModel(accountId: string, siteId: string, scanId: string, modelType: ModelType) {
    const resourceName = `SiteScans/${scanId}/${ModelType[modelType]}` + (modelType === ModelType.GaussianSplats ? '.ply' : '.glb')
    siteBionicsApplcation.service.fetchSiteResourceSasUri(accountId, siteId, resourceName).then((sasUri) => {
      const root = 'https://sitebionicsstorage.blob.core.windows.net/siteblobs/';
      const filename = sasUri.replace(root, '');
      setModelUrlRoot(root);
      setModelUrlFilename(filename);
    });
  }
  function clipModel(model: ILoadedModel) {
      const clipPlane = new Plane(0, 1, 0, -clipHeight);
      model.meshes!.forEach((mesh) => {
        if (mesh.material)
          mesh.material.clipPlane = clipPlane;
      });
  }

  function onModelLoaded(model: ILoadedModel) {
    setLoadedModel(model);
    clipModel(model);
  }

  return (
    <>
      {modelUrlRoot &&
        <ModelWithProgress key={version} rootUrl={`${modelUrlRoot}`} sceneFilename={`${modelUrlFilename}`}
          progressBarColor={Color3.FromInts(255, 165, 0)} center={new Vector3(0, 0, 0)}
          modelRotation={new Vector3(0, 0, 0)} onModelLoaded={onModelLoaded} />}
    </>
  );
})

export default SiteModelLayer;


