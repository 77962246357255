import { Color3, MeshBuilder, Scene, Vector3 } from '@babylonjs/core';
import React, { useEffect } from 'react';
import { useScene } from 'react-babylonjs';


interface GridLinesProps {
    size: number;
    interval: number;
}

const GridLinesLayer: React.FC<GridLinesProps> = ({ size, interval }) => {
    let scene = useScene();
    useEffect(() => {
        if (scene) {
            // Create grid lines
            const lines: Vector3[][] = [];
            for (let i = -size; i <= size; i += interval) {
                lines.push([
                    new Vector3(i, 0, -size),
                    new Vector3(i, 0, size),
                ]);
                lines.push([
                    new Vector3(-size, 0, i),
                    new Vector3(size, 0, i),
                ]);
            }

            const grid = MeshBuilder.CreateLineSystem("grid", {
                lines: lines,
            }, scene);

            grid.color = new Color3(0.7, 0.7, 0.7);
            grid.alpha = 0.5;
            grid.isPickable = false;
            
        }
    }, [scene, size, interval]);

    return null;
};

export default GridLinesLayer;
