import { toZonedTime } from 'date-fns-tz';
import Shift from './Shift';

export interface ShiftInterval {
    intervalStart: Date;
    intervalEnd: Date;
    peopleWorking: ShiftWorkingDetail[];
    numberWorking: number;
}

export interface ShiftWorkingDetail {
    shift: Shift;
    intervalStart: Date;
    intervalEnd: Date;
    startsLate: boolean;
    endsEarly: boolean;
    workingFraction: number; // Fraction of the interval spent working (as a percentage, 1-100%)
}

export class ShiftAnalyzer {
    private shifts: Shift[];

    constructor(shifts: Shift[]) {
        this.shifts = shifts;
    }

    getShiftsDuringPeriod(startTime: Date, endTime: Date, intervalMinutes: number = 60, timezone: string): ShiftInterval[] {
        const intervals: ShiftInterval[] = [];

        for (let current = new Date(startTime); current < endTime; current.setMinutes(current.getMinutes() + intervalMinutes)) {
            const next = new Date(current);
            next.setMinutes(next.getMinutes() + intervalMinutes);

            const peopleWorking = this.shifts
                .map(shift => ({
                    shift,
                    startTimeLocal: toZonedTime(shift.startTime, timezone),
                    endTimeLocal: toZonedTime(shift.endTime, timezone)
                }))
                .filter(({ startTimeLocal, endTimeLocal }) => endTimeLocal > current && startTimeLocal < next)
                .map(({ shift, startTimeLocal, endTimeLocal }) => {
                    const intervalStart = Math.max(current.getTime(), startTimeLocal.getTime());
                    const intervalEnd = Math.min(next.getTime(), endTimeLocal.getTime());
                    const intervalDuration = (intervalEnd - intervalStart) / (60 * 60 * 1000);
                    return {
                        shift,
                        intervalStart: new Date(intervalStart),
                        intervalEnd: new Date(intervalEnd),
                        startsLate: startTimeLocal.getTime() > current.getTime(),
                        endsEarly: endTimeLocal.getTime() < next.getTime(),
                        workingFraction: (intervalDuration / (intervalMinutes / 60)) * 100
                    };
                });

            const numberWorking = peopleWorking.reduce((sum, worker) => sum + worker.workingFraction / 100, 0);

            intervals.push({
                intervalStart: new Date(current),
                intervalEnd: new Date(next),
                peopleWorking,
                numberWorking
            });
        }

        return intervals;
    }

    getShiftsAtTime(queryTime: Date, lengthMinutes: number = 60, timezone: string): ShiftInterval {
        const queryStart = queryTime;
        const queryEnd = new Date(queryStart);
        queryEnd.setMinutes(queryEnd.getMinutes() + lengthMinutes);

        return this.getShiftsDuringPeriod(queryStart, queryEnd, lengthMinutes, timezone)[0];
    }

    getMinMaxStaffedTime(timezone: string): [number, number] {
        let minStaffedTime = Number.MAX_SAFE_INTEGER;
        let maxStaffedTime = Number.MIN_SAFE_INTEGER;

        for (const shift of this.shifts) {
            // Convert the shift start and end to the specified timezone
            const start = toZonedTime(new Date(shift.startTime), timezone);
            const end = toZonedTime(new Date(shift.endTime), timezone);

            const startMinutes = start.getHours() * 60 + start.getMinutes();
            const endMinutes = end.getHours() * 60 + end.getMinutes();

            minStaffedTime = Math.min(minStaffedTime, startMinutes);
            maxStaffedTime = Math.max(maxStaffedTime, endMinutes);
        }

        return [minStaffedTime, maxStaffedTime];
    }
}
