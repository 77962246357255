
export class SiteEventItem {
  itemId: string;
  additionalProperties: Record<string, any> | undefined;

  constructor(itemId: string, additionalProperties: Record<string, any> | undefined) {
    this.itemId = itemId;
    this.additionalProperties = additionalProperties;
  }
}

export class SiteEvent {

  eventDate: Date;
  eventType: string;
  eventId: string;

  eventItems: SiteEventItem[] | undefined;

  additionalProperties: Record<string, any> | undefined;

  constructor(eventDate: Date, eventType: string, eventId: string, eventItems: SiteEventItem[] | undefined, additionalProperties: Record<string, any> | undefined) {
    this.eventDate = eventDate;
    this.eventType = eventType;
    this.eventId = eventId;
    this.eventItems = eventItems;
    this.additionalProperties = additionalProperties;
  }
}

// We need this because javascript will either parse a date in local time, or in UMT. When we import
// dates from a CSV and send to the server, the timezone is an attribute of the site. We need time "without a zone".
// We store it a string to prevent timezone games
export class SiteEventUpdate {

  eventDate: string;
  eventType: string;
  eventId: string;

  eventItems: SiteEventItem[] | undefined;

  additionalProperties: Record<string, any> | undefined;

  constructor(eventDate: string, eventType: string, eventId: string, eventItems: SiteEventItem[] | undefined, additionalProperties: Record<string, any> | undefined) {
    this.eventDate = eventDate;
    this.eventType = eventType;
    this.eventId = eventId;

    this.eventItems = eventItems;
    this.additionalProperties = additionalProperties;
  }
}