import { DataModel, getSnapshot, model, Model, modelAction, prop } from "mobx-keystone";
import { action, computed, makeObservable, observable } from "mobx";
import CameraPose from "../CameraPose";
import EntranceEntity from "./EntranceEntity";
import RegionEntity from "./RegionEntity";
import AreaModelEntity from "./AreaModelEntity";
import CameraEntity from "./CameraEntity";
import Camera from "../Camera";
import TriggerEntity from "./TriggerEntity";
import WallEntity from "./WallEntity";
import { useEffect } from "react";
import Pose from "../Pose";
import { Matrix, Vector3 } from "@babylonjs/core";
import { getLineAndCharacterOfPosition } from "typescript";

@model("SiteBionics/ScanAreaLayout")
export default class ScanAreaLayout extends Model({
  accountId: prop<string>().withSetter(),
  siteId: prop<string>().withSetter(),
  scanAreaId: prop<string>().withSetter(),
  cameras: prop<CameraEntity[]>(() => []).withSetter(),
  areaModels: prop<AreaModelEntity[]>(() => []).withSetter(),
  entrances: prop<EntranceEntity[]>(() => []).withSetter(),
  triggers: prop<TriggerEntity[]>(() => []).withSetter(),
  regions: prop<RegionEntity[]>(() => []).withSetter(),
  walls: prop<WallEntity[]>(() => []).withSetter(),
  defaultWallHeight: prop<number>().withSetter(),
  // this are legacy properties and will move out when we make layouts its own thing
  scanId: prop<string>().withSetter(),
  groundFloorHeight: prop<number>().withSetter(),
  cameraPoses: prop<CameraPose[]>(() => []).withSetter(),
}) {

  @computed get cameraSpaceToWorld() : Matrix {
    var matrix = this.areaModels.find(s => s.modelType === "Lidar")?.pose?.asLHMatrix;
    return matrix ?? Matrix.Identity();
  }

  @modelAction addCamera(camera: CameraEntity) {
    this.cameras.push(camera);
  }

  @modelAction removeCamera(camera: CameraEntity) {
    this.cameras = this.cameras.filter(e => e !== camera);
  }

  @modelAction addEntrance(entrance: EntranceEntity) {
    this.entrances.push(entrance);
  }

  @modelAction removeEntrance(entrance: EntranceEntity) {
    this.entrances = this.entrances.filter(e => e !== entrance);
  }

  @modelAction addTrigger(trigger: TriggerEntity) {
    this.triggers.push(trigger);
  }

  @modelAction removeTrigger(trigger: TriggerEntity) {
    this.triggers = this.triggers.filter(e => e !== trigger);
  }

  @modelAction addRegion(region: RegionEntity) {
    this.regions.push(region);
  }

  @modelAction removeRegion(region: RegionEntity) {
    this.regions = this.regions.filter(r => r !== region);
  }

  @modelAction addWall(wall: WallEntity) {  
    this.walls.push(wall);
  }
  
  @modelAction removeWall(wall: WallEntity) {
    this.walls = this.walls.filter(w => w !== wall);
  }

  @modelAction deleteFloorPlan() {
    this.walls = [];
  }

  @modelAction addAreaModel(scan: AreaModelEntity) {
    this.areaModels.push(scan);
  }

  @modelAction removeAreaModel(scan: AreaModelEntity) {
    this.areaModels = this.areaModels.filter(s => s !== scan);
  }

  onInit() {
    // in the case of a first time edit we don't have any scans so add the default ones
    if (this.areaModels.length === 0) {
      this.addAreaModel(new AreaModelEntity({ scanId: this.scanId, modelType: "Lidar" }));
      this.addAreaModel(new AreaModelEntity({ scanId: this.scanId, modelType: "Photogrammetry", usePoseFromLidar: true }));
      this.addAreaModel(new AreaModelEntity({ scanId: this.scanId, modelType: "GaussianSplats", usePoseFromLidar: true }));
    }
    
    // in the case of a first time edit we don't have any cameras 
    // so migrate them from the cameras poses written out by photogrammetry
    if (this.cameras.length === 0) {
      this.cameraPoses.forEach((cameraPose) => {
        this.addCamera(CameraEntity.fromCameraPose(cameraPose));
      });
    }

     //Generate a cutoff height if it does not exist in the layout
     if (!this.defaultWallHeight) {
      this.defaultWallHeight = 3.5
    }

    this.walls.forEach(wall1 => {
      //Do this to update corner height from old layouts
      if (wall1.startCorner.position.y === 0) {
        wall1.startCorner.position.y = this.defaultWallHeight;
      }

      if (wall1.endCorner.position.y === 0) {
        wall1.endCorner.position.y = this.defaultWallHeight;
      }

      this.walls.forEach(wall2 => {
        if (!(wall1.id === wall2.id)) {
          if (wall1.startCorner.cornerPositionEquals(wall2.startCorner)) { wall1.startCorner.addLinkedCorner(wall2.startCorner); }
          if (wall1.endCorner.cornerPositionEquals(wall2.startCorner)) { wall1.endCorner.addLinkedCorner(wall2.startCorner); }

          if (wall1.startCorner.cornerPositionEquals(wall2.endCorner)) { wall1.startCorner.addLinkedCorner(wall2.endCorner); }
          if (wall1.endCorner.cornerPositionEquals(wall2.endCorner)) { wall1.endCorner.addLinkedCorner(wall2.endCorner); }  
        }   
      });
    });

   
  }

  @modelAction synchCameras(cameras: Camera[]) {
    cameras.forEach((camera) => {
      let cameraEntity = this.cameras.find(c => c.cameraId === camera.cameraId);
      if (cameraEntity) {
        cameraEntity.setCamera(camera);
      }
      else {
        this.addCamera(new CameraEntity({cameraId: camera.cameraId}));
      }
    });
  }
}
