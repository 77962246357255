import React from "react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { IconButton, Select, MenuItem, Button, FormControl, InputLabel, Typography, FormControlLabel, Checkbox, Alert, Box, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { convertTimezoneOnly, minutesToLabel } from "../util/Time";
import Site from "../models/Site";
import DownloadIcon from '@mui/icons-material/Download';
import utc from 'dayjs/plugin/utc';
import { saveAs } from 'file-saver';
import SnapshotViewerDialog from "../components/SnapshotViewerDialog";
import { sha224 } from "js-sha256";
import duration, { Duration } from "dayjs/plugin/duration";
import Shift from '../models/Shift';
import CustomTable, { CellData, RowColumnData, ratioColors, blueColors, getColorFromValue } from "./CustomTable";
import ShiftTable from "./ShiftTable";
import useQueryState from "../hooks/useQueryState";
import { useSiteNavigator } from "../pages/SitePage";
import { roundUpToGranularityDayjs, roundDownToGranularityDayjs } from "../util/Time";
import { ShiftAnalyzer } from "../models/ShiftAnalyzer";
import { toZonedTime } from 'date-fns-tz';
import { SpecialDayChecker } from "../util/SpecialDay";

dayjs.extend(duration);
dayjs.extend(utc);

interface HeatmapData {
  minutes: number;
  days: Array<{ date: Dayjs, day: string, min: number, avg: number, max: number, count: number, employees: number, ratio: number }>;
}

export function exportOccupancyDataToCSV2(heatmapData: HeatmapData[], site: Site, fileName: string = 'occupancy_data.csv') {
  const csvRows: string[] = [];

  // Add the header row
  const headerRow = ['Date/Time', 'Count'];
  csvRows.push(headerRow.join(','));

  // Loop through each day (0 to 6)
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    // Loop through each time slot
    heatmapData.forEach(hourData => {
      if (hourData.minutes >= site.openHours[dayIndex * 2] && hourData.minutes <= site.openHours[dayIndex * 2 + 1]) {
        const dayData = hourData.days[dayIndex];
        const row = [`${dayData.date.format('MM/DD/YYYY')} ${minutesToLabel(hourData.minutes, true)}`, dayData.avg];
        csvRows.push(row.join(','));
      }
    });
  }

  // Create the CSV content
  const csvContent = csvRows.join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Use file-saver to save the file
  saveAs(blob, fileName);
}

const OccupancyTable: React.FC<{ taggedObjectCounts: TaggedObjectCounts[], shiftData: Shift[], granularity: number, displayHours: string, displayData: string, siteDate: Dayjs, site: Site }> = observer(({ taggedObjectCounts, shiftData, granularity, displayHours, displayData, siteDate, site }) => {

  const siteBionicsApplication = useSiteBionicsApplication();
  
  // Snapshot viewer dialog
  const [snapshotViewerDialogOpen, setSnapshotViewerDialogOpen] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState<Dayjs | undefined>(undefined);
  const [selectedDuration, setSelectedDuration] = useState<Duration | undefined>(undefined);
  
  // Data used for rendering
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);

  // Data for rendering in the table
  const [tableData, setTableData] = useState<{ values: CellData[][], rowLabels: RowColumnData[], columnHeaders: RowColumnData[], average: number, min: number, max: number}>({
    values: [],
    rowLabels: [],
    columnHeaders: [],
    average: 0,
    min: 0,
    max: 0
  });  

  const [shiftAnalyzer, setShiftAnalyzer] = useState<ShiftAnalyzer>(new ShiftAnalyzer(shiftData));

  const getEmployeeCount = (dayjsDate: Dayjs, granularity: number, timeZone: string): number => {
    if (!shiftData) return 0;    

    const shiftInterval = shiftAnalyzer.getShiftsAtTime(toZonedTime(dayjsDate.toDate(), timeZone), granularity, timeZone);

    return shiftInterval.numberWorking;
  }

  // If the tagged objects or the granularity change, calculate the heat map
  useEffect(() => {

    if (taggedObjectCounts === null) return;

    const data = taggedObjectCounts.reduce<HeatmapData[]>((acc: HeatmapData[], toc: TaggedObjectCounts) => {

      const minutesInDay = dayjs(toc.time).hour() * 60 + dayjs(toc.time).minute();
      const minutes = Math.floor(minutesInDay / granularity) * granularity;

      const day = dayjs(toc.time).format('ddd M/D/YY');
      const date = dayjs(toc.time).startOf('day');

      let hourData = acc.find(h => h.minutes === minutes);
      if (!hourData) {
        hourData = { minutes: minutes, days: [] };
        acc.push(hourData);
      }

      let dayData = hourData.days.find(d => d.day === day);

      if (!dayData) {
        const employees = getEmployeeCount(dayjs(toc.time), granularity, site.timeZone);        
        dayData = { date: date, day: day, min: toc.min, avg: toc.avg, max: toc.max, count: 1, employees: employees, ratio: employees > 0 ? toc.avg / employees : 0 };
        hourData.days.push(dayData);
      } else {
        dayData.min = Math.min(dayData.min, toc.min);
        dayData.max = Math.max(dayData.max, toc.max);
        dayData.avg = (dayData.avg * dayData.count + toc.avg) / (dayData.count + 1);
        dayData.ratio = dayData.employees > 0 ? dayData.avg / dayData.employees : 0;

        dayData.count += 1;
      }

      return acc;
    }, []);

    setHeatmapData(data);

  }, [taggedObjectCounts, shiftData, granularity]);

  useEffect(() => {
    if (heatmapData.length === 0) return;

    // Calculate the min/max staffed time
    const [minStaffedTime, maxStaffedTime] = shiftAnalyzer.getMinMaxStaffedTime(site.timeZone);
    const [minOpenTime, maxCloseTime] = site.getMinMaxOpenHours();

    const filteredHeatmapData = heatmapData.filter((hourData) => {
      const minutes = hourData.minutes;
      return (displayHours === "All" ||
        (displayHours === "Open" && (minutes >= minOpenTime && minutes < maxCloseTime)) ||
        (displayHours === "Staffed" && (minutes >= minStaffedTime && minutes < maxStaffedTime)));
    });

    let total = 0;
    let count = 0;
    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;

    filteredHeatmapData.forEach((hourData) => {
      hourData.days.forEach((dayData) => {
        let yValue = dayData.avg;
        if (displayData === "Ratio") {
          yValue = dayData.ratio;
        } else if (displayData === "Min") {
          yValue = dayData.min;
        } else if (displayData === "Max") {
          yValue = dayData.max;
        }

        if (yValue && yValue > 0) {
          total += yValue;
          count++;
          if (yValue > max) max = yValue;
          if (yValue < min) min = yValue;
        }
      });
    });

    const newValues: CellData[][] = filteredHeatmapData.map((hourData) => {
      return hourData.days.map((dayData) => {
        let label = dayData.avg.toFixed(1);
        let yValue = dayData.avg;
        if (displayData === "MinAvgMax") {
          label = `${dayData.min} / ${dayData.avg.toFixed(1)} / ${dayData.max}`;
        } else if (displayData === "Ratio") {
          label = dayData.ratio.toFixed(1);
          yValue = dayData.ratio;
        } else if (displayData === "Min") {
          label = dayData.min.toFixed(1);
          yValue = dayData.min;
        } else if (displayData === "Max") {
          label = dayData.max.toFixed(1);
          yValue = dayData.max;
        }

        const tooltip = `
          <div style="padding: 5px;">
            <strong>Average:</strong> ${dayData.avg.toFixed(1)}<br/>
            <strong>Min:</strong> ${dayData.min}<br/>
            <strong>Max:</strong> ${dayData.max}<br/>
            <strong>Staff:</strong> ${dayData.employees}<br/>
            <strong>Ratio:</strong> ${dayData.ratio.toFixed(1)}<br/>
          </div>`;

        let backgroundColor = "";
        if (yValue === 0) {
          backgroundColor = "grey";
        } else if (displayData === "Ratio") {
          backgroundColor = getColorFromValue(ratioColors, min, max, yValue);
        } else {
          backgroundColor = getColorFromValue(blueColors, min, max, yValue);
        }
        return {
          label: label,
          value: yValue,
          tooltip: tooltip,
          textColor: "black",
          backgroundColor: backgroundColor,
          data: { date: dayData.date, minutes: hourData.minutes, value: yValue }
        };
      });
    });

    const newRowLabels = filteredHeatmapData.map((hourData) => ({ label: minutesToLabel(hourData.minutes, false) }));

    const specialDayChecker = new SpecialDayChecker();

    const newColumnHeaders = heatmapData[0]?.days.map((day) => {
      const specialDay = specialDayChecker.getSpecialDay(day.date.toDate());
      return {
        label: day.date.format('ddd M/D/YY'),
        textColor: specialDay ? 'red' : undefined,
        tooltip: specialDay ? specialDay.name : undefined
      };
    }) || [];


    const averageRow: CellData[] = newColumnHeaders.map((_, colIndex) => {
      const columnValues = newValues.map(row => row[colIndex].data.value);
      const columnAverage = columnValues.reduce((sum, value) => sum + value, 0) / columnValues.length;
      return {
        label: columnAverage.toFixed(1),
        value: columnAverage,
        textColor: "black",
        backgroundColor: "#e0e0e0",
        data: { value: columnAverage }
      };
    });
    newValues.push(averageRow);
    newRowLabels.push({ label: "Average" });

    setTableData({
      values: newValues,
      rowLabels: newRowLabels,
      columnHeaders: newColumnHeaders,
      average: total / count,
      min: min,
      max: max
    });
    
  }, [heatmapData, displayHours, displayData, granularity, shiftData, site]);

  const handleTableDoubleClick = (row: number, col: number, cell: CellData): void => {
    const clickedData = cell.data;
    let date = dayjs(clickedData.date).hour(clickedData.minutes / 60).minute(clickedData.minutes % 60);
    let duration = dayjs.duration({ minutes: granularity });
    setSelectedDuration(duration);
    setSelectedStartTime(date);
    setSnapshotViewerDialogOpen(true);    
  }
  
  if (!tableData || tableData.values.length === 0) {
    return (
      <Box component="div" display="flex">
        <Alert severity="warning">No data for this date.</Alert>
      </Box>
    )
  }

  return (
    <>
      <CustomTable columnHeaders={tableData.columnHeaders} rowLabels={tableData.rowLabels} values={tableData.values} defaultOnDoubleClick={handleTableDoubleClick} />
      <Box component="div" display="flex" flexDirection="row" gap="20px" alignItems="center" justifyContent="right">
        <Typography>Overall Average: {tableData.average.toFixed(2)}</Typography>
        <Typography>Min: {tableData.min.toFixed(2)}</Typography>
        <Typography>Max: {tableData.max.toFixed(2)}</Typography>
        <IconButton
          color="primary"
          onClick={() => exportOccupancyDataToCSV2(heatmapData, site)}
          sx={{ marginLeft: 2 }}
        >
          <DownloadIcon />
        </IconButton>
      </Box>          
      {selectedDuration && selectedStartTime && (
        <SnapshotViewerDialog
          open={snapshotViewerDialogOpen}
          onClose={() => { setSnapshotViewerDialogOpen(false); }}
          startTime={selectedStartTime}
          duration={selectedDuration}
        />
      )}
    </>
  );

});

export default OccupancyTable;
