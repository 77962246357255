import { FunctionComponent } from 'react';
import { useSelectionManager } from '../util/SelectionManager';
import { observer } from 'mobx-react';
import CameraProperties from './CameraProperties';
import Camera from '../models/Camera';
import ScanArea from '../models/ScanArea';
import EntranceEntity from '../models/layout/EntranceEntity';
import EntranceProperties from './EntranceProperties';
import RegionEntity from '../models/layout/RegionEntity';
import RegionProperties from './RegionProperties';
import { useScanAreaLayoutViewModel } from '../models/layout/ScanAreaLayoutViewModel';
import AreaModelProperties from './AreaModelProperties';
import AreaModelEntity from '../models/layout/AreaModelEntity';
import CameraEntity from '../models/layout/CameraEntity';
import TriggerProperties from './TriggerProperties';
import TriggerEntity from '../models/layout/TriggerEntity';
import WallEntity from '../models/layout/WallEntity';
import WallProperties from './WallProperties';
import DoorProperties from './DoorProperties';
import DoorEntity from '../models/layout/DoorEntity';
import WindowProperties from './WindowProperties';
import WindowEntity from '../models/layout/WindowEntity';


const SelectEntityPropertiesPane: FunctionComponent = observer(() =>  {
  const layoutViewModel = useScanAreaLayoutViewModel();

    function controlForSelectionType(selection: any) {
         
        switch (true) {
            case selection instanceof CameraEntity:
                return <CameraProperties camera={selection} />;
            case selection instanceof EntranceEntity:
                  return <EntranceProperties entrance={selection} />;
            case selection instanceof TriggerEntity:
                    return <TriggerProperties trigger={selection} />;
            case selection instanceof RegionEntity:
                  return <RegionProperties region={selection} />;
            // case selection instanceof CornerEntity:
            //       return <CornerProperties corner={selection}/>;
            case selection instanceof WallEntity:
                    return <WallProperties wall={selection} />
            case selection instanceof DoorEntity:
                    return <DoorProperties door={selection} />
            case selection instanceof WindowEntity:
                    return <WindowProperties window={selection} />
            case selection instanceof AreaModelEntity:
                    return <AreaModelProperties areaModel={selection} />
  
                  
            // case selection instanceof ScanArea:
            //         return <CameraProperties camera={selection} />;
            default:
                return "todo..." + typeof selection;
        }
    }

  return (
    <>
      {layoutViewModel &&
        <>
          {layoutViewModel!.selectedEntity && controlForSelectionType(layoutViewModel!.selectedEntity)}
        </>
      }
    </>
  );
});

export default SelectEntityPropertiesPane;