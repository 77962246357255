import { ExtendedModel, model, prop } from "mobx-keystone";
import Vector3Model from "./Vector3Model";
import LayoutEntity from "./LayoutEntity";
import { action } from "mobx";

@model("SiteBionics/CornerEntity")
export default class CornerEntity extends ExtendedModel(LayoutEntity, {
    name: prop<string>().withSetter(),
    position: prop<Vector3Model>().withSetter(),
    isStartCorner: prop<boolean>().withSetter(),
}) {
    linkedCorners = new Array<CornerEntity>();
    unlinkedCorners = new Array<CornerEntity>(); //Keep a list of which corners were manually unlinked as to not relink them automatically

    @action
    addLinkedCorner(corner: CornerEntity) {

        const allLinkedCorners = [...this.linkedCorners];

        if (!allLinkedCorners.includes(corner)) {
            allLinkedCorners.push(corner);
        }

        corner.linkedCorners.forEach(c => {
            if (!allLinkedCorners.includes(c)) {
                allLinkedCorners.push(c);
            }
        });

        allLinkedCorners.forEach(c => {
            c.linkedCorners = allLinkedCorners;
        });
    }

    @action
    removeLinkedCorner(toRemove: CornerEntity) {
        if (this.linkedCorners.includes(toRemove)) {
            this.linkedCorners = this.linkedCorners.filter((corner) => corner !== toRemove);
        }
    }

    @action
    unlinkCorner() {
        this.linkedCorners.forEach(c => c.removeLinkedCorner(this))
        this.linkedCorners = new Array<CornerEntity>();
        this.unlinkedCorners = new Array<CornerEntity>();
    }

    @action
    removeUnlinkedCorner(toRemove: CornerEntity) {
        if (this.unlinkedCorners.includes(toRemove) && toRemove.id != this.id) {
            this.unlinkedCorners = this.unlinkedCorners.filter((corner) => corner !== toRemove);
        }
    }

    cornerIsUnlinked = (toCheck: CornerEntity) => {
        return this.unlinkedCorners.includes(toCheck);
    }

    @action
    commitPositionUpdate(x: number, y: number, z: number) {
        this.position.setX(x);
        this.position.setY(y);
        this.position.setZ(z);
    }

    updateAllLinkedCornerPositions() {
        this.linkedCorners.forEach(c => c.setPosition(new Vector3Model({x: this.position.x, y: this.position.y, z: this.position.z})));
    }

    cornerPositionEquals(other: CornerEntity) {
        return (
            this.position.x === other.position.x &&
            this.position.y === other.position.y &&
            this.position.z === other.position.z);
    }
}