import { FunctionComponent, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react"
import { Color3, Color4 } from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import { ActionManager, ExecuteCodeAction, KeyboardEventTypes, LinesMesh, Mesh, MeshBuilder, Nullable, Scene } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import Vector3Model from "../models/layout/Vector3Model";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";
import WallEntity from "../models/layout/WallEntity";
import CornerEntity from "../models/layout/CornerEntity";
import DoorEntity from "../models/layout/DoorEntity";
import WindowEntity from "../models/layout/WindowEntity";
import ScanAreaLayout from "../models/layout/ScanAreaLayout";

export const maximumHeightForWallFeature = (parentWall: WallEntity, feature: DoorEntity | WindowEntity, forceNonSlanted : boolean = false) => {
  const { startCorner, endCorner } = parentWall;
  const { x: x1, y: y1, z: z1 } = startCorner.position;
  const { x: x2, y: y2, z: z2 } = endCorner.position;

  const wallLength = Math.sqrt((x2 - x1) ** 2 + (z2 - z1) ** 2);

  const featureHalfWidth = feature.width / 2;
  const leftDistance = Math.max(0, feature.distanceAlongWall - featureHalfWidth / wallLength);
  const rightDistance = Math.min(1, feature.distanceAlongWall + featureHalfWidth / wallLength);

  const leftY = y1 + (y2 - y1) * leftDistance;
  const rightY = y1 + (y2 - y1) * rightDistance;

  if (!feature.matchWallSlant || forceNonSlanted) {
    return Math.min(leftY, rightY);
  } else {
    return Math.max(leftY, rightY);
  }
};    


export const drawWallLines = (wall: WallEntity, scanAreaLayout: ScanAreaLayout, scene: Nullable<Scene>, selectedEntity: any): 
  {wallLineSystemMap: Map<WallEntity, LinesMesh>, doorLineSystemMap: Map<DoorEntity, LinesMesh>, windowLineSystemMap: Map<WindowEntity, LinesMesh>} => {
  
  
  let wallLineSystemMap = new Map<WallEntity, LinesMesh>();
  let doorLineSystemMap = new Map<DoorEntity, LinesMesh>();
  let windowLineSystemMap = new Map<WindowEntity, LinesMesh>();

  let wallGroundFloorHeight = 0

  //draw the vertical line
  let wallPoints = [
    new Vector3(-wall.startCorner.position.x, wallGroundFloorHeight, wall.startCorner.position.z), 
    new Vector3(-wall.endCorner.position.x, wallGroundFloorHeight, wall.endCorner.position.z),
    new Vector3(-wall.endCorner.position.x, wallGroundFloorHeight + wall.endCorner.position.y, wall.endCorner.position.z),
    new Vector3(-wall.startCorner.position.x, wallGroundFloorHeight + wall.startCorner.position.y, wall.startCorner.position.z), 
    new Vector3(-wall.startCorner.position.x, wallGroundFloorHeight, wall.startCorner.position.z),  
  ]

  let wallIsEmpty = (wall.doors.length === 0 && wall.windows.length === 0);  
  let wallColor3 = (selectedEntity === wall) ? Color3.Green() : Color3.White();
  
  const wallColor4 = new Color4(wallColor3.r, wallColor3.g, wallColor3.b, 1)
  const wallColorsTemp = wallPoints.map(p => wallColor4)

  const wallLineSystem = MeshBuilder.CreateLineSystem("wallLineSystem", {
    lines: [wallPoints],
    colors: [wallColorsTemp],
  }, scene);

  wallLineSystemMap.set(wall, wallLineSystem);

  if (!wallIsEmpty) {
    const selectedEntityColor3 = Color3.Red();
    const selectedEntityColor4 = new Color4(selectedEntityColor3.r, selectedEntityColor3.g, selectedEntityColor3.b, 1);

    //Draw doors
    const doorColor3 = Color3.Yellow();
    const doorColor4 = new Color4(doorColor3.r, doorColor3.g, doorColor3.b, 1);

    wall.doors.forEach(door => {
      const { featureStart, featureEnd } = calculateStartAndEndOfWallFeature(wall.startCorner, wall.endCorner, door);  
      const doorPoints = [
        new Vector3(-featureStart.x, wallGroundFloorHeight, featureStart.z),
        new Vector3(-featureStart.x, featureStart.y, featureStart.z),
        new Vector3(-featureEnd.x, featureEnd.y, featureEnd.z),
        new Vector3(-featureEnd.x, wallGroundFloorHeight, featureEnd.z),
        new Vector3(-featureStart.x, wallGroundFloorHeight, featureStart.z)
      ];
      const doorColorsTemp = doorPoints.map(p => (door === selectedEntity ? selectedEntityColor4 : doorColor4));
      
      const doorLineSystem = MeshBuilder.CreateLineSystem("doorLineSystem", {
        lines: [doorPoints],
        colors: [doorColorsTemp],
      }, scene);

      doorLineSystemMap.set(door, doorLineSystem);

    });

    const windowColor3 = Color3.Blue();
    const windowColor4 = new Color4(windowColor3.r, windowColor3.g, windowColor3.b, 1);

    //Draw windows
    wall.windows.forEach(window => {
      const { featureStart, featureEnd } = calculateStartAndEndOfWallFeature(wall.startCorner, wall.endCorner, window);
      const windowLowerY = wallGroundFloorHeight + window.verticalHeight

      const windowPoints = [
        new Vector3(-featureStart.x, windowLowerY, featureStart.z),
      //To not execeed max height
      new Vector3(-featureStart.x, wallGroundFloorHeight + featureStart.y, featureStart.z),
      new Vector3(-featureEnd.x, wallGroundFloorHeight + featureEnd.y, featureEnd.z),
      new Vector3(-featureEnd.x, windowLowerY, featureEnd.z),
      new Vector3(-featureStart.x, windowLowerY, featureStart.z)];

      const windowColorsTemp = windowPoints.map(p => (selectedEntity === window ? selectedEntityColor4 : windowColor4));

      const windowLineSystem = MeshBuilder.CreateLineSystem("windowLineSystem", {
        lines: [windowPoints],
        colors: [windowColorsTemp],
      }, scene);

      windowLineSystemMap.set(window, windowLineSystem);
    });
  }

  return {wallLineSystemMap, doorLineSystemMap, windowLineSystemMap};
}

export const calculateStartAndEndOfWallFeature = (startCorner: CornerEntity, endCorner: CornerEntity, feature: DoorEntity | WindowEntity): { featureStart: Vector3; featureEnd: Vector3 } => {
  //TODO: Fix:
  const wallStart = new Vector3(
    startCorner.position.x,
    0,
    startCorner.position.z
  );
  const wallEnd = new Vector3(
    endCorner.position.x,
    0,
    endCorner.position.z
  );

  const percentageAlongWall = feature.distanceAlongWall;
  const width = feature.width;

  const wallDirection = wallEnd.subtract(wallStart).normalize();
  const wallLength = Vector3.Distance(wallStart, wallEnd);

  const doorMidpointOffset = percentageAlongWall * wallLength;
  const doorMidpoint = wallStart.add(wallDirection.scale(doorMidpointOffset));

  const halfDoorWidth = width / 2;

  let featureStart = doorMidpoint.subtract(wallDirection.scale(halfDoorWidth));
  let featureEnd = doorMidpoint.add(wallDirection.scale(halfDoorWidth));

  if (Vector3.Distance(wallStart, featureStart) < 0) {
    featureStart = wallEnd;
  }
  if (Vector3.Distance(wallEnd, featureEnd) < 0) {
    featureEnd = wallStart;
  }

  if (Vector3.Distance(featureStart, wallEnd) > wallLength) {
    featureStart = wallStart;
  }
  if (Vector3.Distance(featureEnd, wallStart) > wallLength) {
    featureEnd = wallEnd;
  }

  featureStart.y = (feature instanceof DoorEntity) ? feature.height : feature.windowHeight + feature.verticalHeight;
  featureEnd.y = (feature instanceof DoorEntity) ? feature.height : feature.windowHeight + feature.verticalHeight;

  if (feature.matchWallSlant) {
    const { x: x1, y: y1, z: z1 } = startCorner.position;
    const { x: x2, y: y2, z: z2 } = endCorner.position;

    const deltaHeight = y2 - y1;
    const deltaX = x2 - x1;
    const slope = deltaHeight / deltaX;

    const featureHeight = (feature instanceof DoorEntity) ? feature.height : feature.windowHeight;
    

    const startCornerSideHeight = (startCorner.position.y >= endCorner.position.y) ? 
      featureHeight : featureHeight + slope * (featureStart.x - featureEnd.x);
    const endCornerSideHeight = (startCorner.position.y < endCorner.position.y) ? 
      featureHeight : featureHeight + slope * (featureEnd.x - featureStart.x);

    featureStart.y = startCornerSideHeight;
    featureEnd.y = endCornerSideHeight;
   
    if (feature instanceof WindowEntity) {
      featureStart.y = featureStart.y + feature.verticalHeight;
      featureEnd.y = featureEnd.y + feature.verticalHeight;
    }
  }

  return { featureStart, featureEnd };

};


const WallLayer: FunctionComponent<{wall: WallEntity, layoutViewModel: ScanAreaLayoutViewModel}> = observer(({wall, layoutViewModel}) => {
  const startNodeTransformMeshRef = useRef<Mesh>(null);
  const endNodeTransformMeshRef = useRef<Mesh>(null);
  
  const meshRef = useRef<Mesh>(null);
  const scene = useScene();

  //Watch for control key
  let isControlKeyPressed = false;

  scene?.onKeyboardObservable.add((keyboardInfo) => {  
    switch (keyboardInfo.type) {
      case KeyboardEventTypes.KEYDOWN:
        if (keyboardInfo.event.key === "Control") {
          isControlKeyPressed = true;
        }
        break;
      case KeyboardEventTypes.KEYUP:
        if (keyboardInfo.event.key === "Control") {
          isControlKeyPressed = false;
        }
        break;
    }
  });

  useEffect(() => {
    if (scene && meshRef.current) {
        meshRef.current.actionManager = new ActionManager(scene);
        meshRef.current.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnPickTrigger, (event: any) => {
                layoutViewModel.selectEntity(wall);
            })
        );
    }
  }, [scene, meshRef.current]);

  let wallOrChildSelected = (wall === layoutViewModel.selectedEntity);

  useEffect(() => { 
    const {wallLineSystemMap, doorLineSystemMap, windowLineSystemMap} = drawWallLines(wall, layoutViewModel.scanAreaLayout, scene, layoutViewModel.selectedEntity);
    const allLineMeshes: LinesMesh[] = []

    wallLineSystemMap.forEach((lineSystem, wall) => {
      allLineMeshes.push(lineSystem);
      lineSystem.actionManager = new ActionManager(scene);
      
      lineSystem.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, (evt) => {
          layoutViewModel.selectEntity(wall);
        })
      );
    });

    doorLineSystemMap.forEach((lineSystem, door) => {
      allLineMeshes.push(lineSystem);
      lineSystem.actionManager = new ActionManager(scene);
      
      lineSystem.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, (evt) => {
          layoutViewModel.selectEntity(door);
        })
      );
    });

    windowLineSystemMap.forEach((lineSystem, window) => {
      allLineMeshes.push(lineSystem);
      lineSystem.actionManager = new ActionManager(scene);
      
      lineSystem.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, (evt) => {
          layoutViewModel.selectEntity(window);
        })
      );
    });


        
    return () => {
      allLineMeshes.forEach(lineMesh => {
        lineMesh.dispose();
      });
    };

  }, [wall.startCorner.position.x, wall.startCorner.position.y, wall.startCorner.position.z, wall.endCorner.position.x, wall.endCorner.position.y, wall.endCorner.position.z,  
    layoutViewModel.selectedEntity, wall.doors.length, wall.windows.length,
    wall.doors.map(door => door.height).join(','), wall.doors.map(door => door.width).join(','), wall.doors.map(door => door.distanceAlongWall).join(','), wall.doors.map(door => door.matchWallSlant).join(','), 
    wall.windows.map(window => window.windowHeight).join(','), wall.windows.map(window => window.verticalHeight).join(','), wall.windows.map(window => window.width).join(','), wall.windows.map(window => window.distanceAlongWall).join(','), wall.windows.map(window => window.matchWallSlant).join(',')]);
 
  
  //TODO: Fix
  const commitLinkedCornerPositionUpdates = (updatedCorner: CornerEntity) => {
    updatedCorner.linkedCorners.forEach(corner => {
      corner.commitPositionUpdate(
        updatedCorner.position.x,
        updatedCorner.position.y,
        updatedCorner.position.z
      );
    });
  }

  const updateLinkedCorners = (updatedCorner: CornerEntity) => {
    updatedCorner.linkedCorners.forEach(corner => {
      corner.setPosition(updatedCorner.position);
    });
  }
  

  const calculateDistance = (start: Vector3Model, end: Vector3Model) => {
    return Math.sqrt(
      Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2) + Math.pow(end.z - start.z, 2)
    );
  };
  
  const snapToCorner = (toSnap: CornerEntity, toSnapParentWall: WallEntity) => {
    const snapToThreshold = 1;
    
    let minDistFound = snapToThreshold;
    let closestCornerFound = null;

    const otherCornerOnWall = toSnap.isStartCorner ? toSnapParentWall.endCorner : toSnapParentWall.startCorner;

    let walls = layoutViewModel.scanAreaLayout.walls;
    for (let i = 0; i < walls.length; i++) {
      if (walls[i] != wall) {
        const wallStart = walls[i].startCorner;
        const wallEnd = walls[i].endCorner;

        const cornerDistanceToStart = calculateDistance(toSnap.position, wallStart.position);
        if (cornerDistanceToStart < minDistFound && !toSnap.cornerIsUnlinked(wallStart) && !wallStart.cornerIsUnlinked(toSnap)) {
          //Make sure the other corner on the parent wall isn't already linked
          if (!(otherCornerOnWall.linkedCorners.includes(wallStart))) {
            minDistFound = cornerDistanceToStart;
            closestCornerFound = wallStart;
          }
        }
        
        const cornerDistanceToEnd = calculateDistance(toSnap.position, wallEnd.position);
        if (cornerDistanceToEnd < minDistFound && !toSnap.cornerIsUnlinked(wallEnd) && !wallEnd.cornerIsUnlinked(toSnap)) {
          //Make sure the other corner on the parent wall isn't already linked
          if (!(otherCornerOnWall.linkedCorners.includes(wallEnd))) {
            minDistFound = cornerDistanceToEnd;
            closestCornerFound = wallEnd;
          } 
        }
      }
    }

    if (closestCornerFound === null) { return; }
    
    toSnap.setPosition(closestCornerFound.position);
    
    //Set snap to and snapped to as linked corners
    toSnap.addLinkedCorner(closestCornerFound);
    closestCornerFound.addLinkedCorner(toSnap);
  }

  //Change this if a wall's lower corner can have a y != 0
  const wallGroundFloorHeight = 0

  return (
    <>
      {/** Start Corner */}
      <transformNode name='transformNodeStartCorner' ref={startNodeTransformMeshRef} position={new Vector3(-wall.startCorner.position.x, wallGroundFloorHeight, wall.startCorner.position.z)}>
        {((wallOrChildSelected && layoutViewModel.showSpecificCornerId === "") || (layoutViewModel.showSpecificCornerId === wall.startCorner.id)) &&
          <>
            <lines name="lines" points={[new Vector3(0, -1, 0), new Vector3(0, 1, 0)]} color={Color3.Red()} />
            <sphere name="sphereStart" diameter={0.25} position={new Vector3(0, 0, 0)}/>
            <sphere name="upperSphereStart" diameter={0.25} position={new Vector3(0, wall.startCorner.position.y, 0)}/>
            <sphere name="invisibleSphereStart" diameter={1} visibility={0} position={new Vector3(0, 0, 0)}/>
            <pointerDragBehavior
              dragPlaneNormal={new Vector3(0, 1, 0)}
              useObjectOrientationForDragging={true}
              onDragObservable={(e : any) => {
                wall.startCorner.position.setX(-startNodeTransformMeshRef.current!.position.x); 
                wall.startCorner.position.setZ(startNodeTransformMeshRef.current!.position.z);
                if (!isControlKeyPressed) { updateLinkedCorners(wall.startCorner); }
                if (isControlKeyPressed) {
                  wall.startCorner.unlinkCorner();
                }
              }}
              onDragEndObservable={(e: any) => {
                wall.startCorner.position.setX(-startNodeTransformMeshRef.current!.position.x); 
                wall.startCorner.position.setZ(startNodeTransformMeshRef.current!.position.z);
                if (!isControlKeyPressed) { snapToCorner(wall.startCorner, wall); }
                wall.doors.forEach(door => door.updateDoorHeight(wall));
              }}
            />
          </>
        }
      </transformNode>

      {/** End Corner */}
      <transformNode name='transformNodeEndCorner' ref={endNodeTransformMeshRef} position={new Vector3(-wall.endCorner.position.x, wallGroundFloorHeight, wall.endCorner.position.z)}>
        {((wallOrChildSelected && layoutViewModel.showSpecificCornerId === "") || (layoutViewModel.showSpecificCornerId === wall.endCorner.id)) &&
          <>
            <lines name="lines" points={[new Vector3(0, -1, 0), new Vector3(0, 1, 0)]} color={Color3.Red()} />
            <sphere name="sphereEnd" diameter={0.25} position={new Vector3(0, 0, 0)}/>
            <sphere name="upperSphereEnd" diameter={0.25} position={new Vector3(0, wall.endCorner.position.y, 0)}/>
            <sphere name="invisibleSphereEnd" diameter={1} visibility={0} position={new Vector3(0, 0, 0)}/>
            <pointerDragBehavior
              dragPlaneNormal={new Vector3(0, 1, 0)}
              useObjectOrientationForDragging={true}
              onDragObservable={(e : any) => {
                wall.endCorner.position.setX(-endNodeTransformMeshRef.current!.position.x); 
                wall.endCorner.position.setZ(endNodeTransformMeshRef.current!.position.z);
                if (!isControlKeyPressed) { updateLinkedCorners(wall.endCorner); }
                if (isControlKeyPressed) {
                  wall.endCorner.unlinkCorner();
                }
              }}
              onDragEndObservable={(e: any) => {
                wall.endCorner.position.setX(-endNodeTransformMeshRef.current!.position.x); 
                wall.endCorner.position.setZ(endNodeTransformMeshRef.current!.position.z);
                if (!isControlKeyPressed) { snapToCorner(wall.endCorner, wall); }
                wall.doors.forEach(door => door.updateDoorHeight(wall));
              }}
            />
          </>
        }
      </transformNode>
    </>
  )
})
  
const WallsLayer: FunctionComponent<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {
  return (
    <>
      <utilityLayerRenderer>
      {layoutViewModel.scanAreaLayout?.walls?.map((wall, index) => (
        <WallLayer key={`wall-${index}`} wall={wall} layoutViewModel={layoutViewModel}/>
      ))}
      </utilityLayerRenderer>

    </>
  )
})

export default WallsLayer;
