import { FC, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react"

import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import 'm-react-splitters/lib/splitters.css';
import { encodeColor } from "../util/Colors";
import Tracklet, { TrackletType } from "../models/Tracklet";
import { Color3 } from "@babylonjs/core";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import SiteNavigator from "../models/SiteNavigator";

interface TrackletsTimelineProps {
    selectTracklet: (tracklet: Tracklet) => void;
    siteNavigator: SiteNavigator;
}

const TrackedObjectsTimeline: FC<TrackletsTimelineProps> = observer(({
    selectTracklet, siteNavigator }) => {

    const siteBionicsApplcation = useSiteBionicsApplication();
    // We'll store row refs per camera ID (rather than per tracklet) 
    const rowRefs = useRef<{ [cameraId: string]: HTMLTableRowElement | null }>({});
    const [tracklets, setTracklets] = useState<Tracklet[]>([]);

    const minTime = siteNavigator.startTime?.toDate().getTime() ?? 0;
    const maxTime = siteNavigator.endTime?.toDate().getTime() ?? 0;
    const timeSpan = maxTime - minTime;

    useEffect(() => {
        let filteredTracklets = siteNavigator.currentTrackedObject?.tracklets
            .filter(tracklet => 
                tracklet.trackletType === TrackletType.CameraDetection
                && tracklet.startTime.getTime() <= maxTime
                && tracklet.endTime.getTime() >= minTime
            )
            .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
        setTracklets(filteredTracklets ?? []);
    }, [siteNavigator.currentTrackedObject, maxTime, minTime]);

    // Group tracklets by camera ID
    const groupedTracklets = useMemo(() => {
        const map = new Map<string, Tracklet[]>();
        for (const t of tracklets) {
            // You can adjust how you handle missing camera ID below:
            const camId = t.camera?.cameraId ?? "UnknownCamera";
            if (!map.has(camId)) {
                map.set(camId, []);
            }
            map.get(camId)!.push(t);
        }
        // Convert Map to an array of [cameraId, trackletsForThatCamera]
        return Array.from(map.entries());
    }, [tracklets]);

    // Scroll to the row for the selected tracklet's camera ID, if needed
    useEffect(() => {
        if (siteNavigator.currentTracklet) {
            const cameraId = siteNavigator.currentTracklet.camera?.cameraId;
            if (cameraId && rowRefs.current[cameraId]) {
                rowRefs.current[cameraId]?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [siteNavigator.currentTracklet]);

    // Single-click/double-click handlers
    const handleClick = (event: React.MouseEvent<unknown>, trackletId: string) => {
        const tracklet = tracklets.find(t => t.trackletId === trackletId);
        if (tracklet) {
            selectTracklet(tracklet);
        }
    };

    const handleDoubleClick = (event: React.MouseEvent<unknown>, trackletId: string) => {
        const tracklet = tracklets.find(t => t.trackletId === trackletId);
        if (tracklet) {
            selectTracklet(tracklet);
        }
    };

    // Adjust bar positioning so each tracklet is absolutely placed within the timeline cell
    const getBarStyle = (tracklet: Tracklet) => {
        let startTime = tracklet.startTime.getTime();
        let endTime = tracklet.endTime.getTime();
        startTime = Math.max(startTime, minTime);
        endTime = Math.min(endTime, maxTime);

        const leftOffset = ((startTime - minTime) / timeSpan) * 100;
        const width = ((endTime - startTime) / timeSpan) * 100;
        // Use camera color or default
        const color = tracklet.isStationary ? Color3.Teal() : (tracklet.camera?.color ?? Color3.White());

        return {
            position: 'absolute' as const,          // Important for multiple bars
            left: `${leftOffset}%`,
            width: `${width}%`,
            height: '100%',
            backgroundColor: encodeColor(color),
            borderRadius: '3px',
            cursor: 'pointer',
        };
    };

    const getDurationInSeconds = (tracklet: Tracklet) => {
        const durationMs = tracklet.endTime.getTime() - tracklet.startTime.getTime();
        return (durationMs / 1000).toFixed(2); // duration in seconds
    };

    return (
        <Box component="div">
            {tracklets.length > 0 && (
                <TableContainer
                    sx={{ width: "100%", height: "100%", paddingLeft: "10pt", paddingRight: "10pt" }}
                >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={30}>#</TableCell>
                                {/* <TableCell width={100}>Camera ID</TableCell> */}
                                <TableCell>Timeline</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowX: "auto" }}>
                            {groupedTracklets.map(([cameraId, cameraTracklets], rowIndex) => (
                                <TableRow
                                    key={cameraId}
                                    ref={(el) => (rowRefs.current[cameraId] = el)}
                                >
                                    <TableCell>{rowIndex}</TableCell>
                                    {/* <TableCell>{cameraId}</TableCell> */}
                                    <TableCell>
                                        {/* Single timeline cell for all tracklets from this camera */}
                                        <div style={{ position: 'relative', width: '100%', height: '20px' }}>
                                            {cameraTracklets.map(tracklet => (
                                                <Tooltip
                                                    key={tracklet.trackletId}
                                                    title={`Duration: ${getDurationInSeconds(tracklet)} s
                                                            Enter: ${tracklet.startsAtEntrance}
                                                            Exit: ${tracklet.endsAtEntrance}`}
                                                >
                                                    <div
                                                        style={getBarStyle(tracklet)}
                                                        onClick={(event) => handleClick(event, tracklet.trackletId)}
                                                        onDoubleClick={(event) => handleDoubleClick(event, tracklet.trackletId)}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {tracklets.length === 0 && (
                <p>No tracklets. Select a time range and hit 'refresh' then select a tracked object.</p>
            )}
        </Box>
    );
});

export default TrackedObjectsTimeline;
