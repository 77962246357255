import { Vector3 } from "@babylonjs/core";
import { dotNetTicksToDate } from "../util/Time";



export default class TrackletDataPoint {

    trackletId: string;
    time: Date;
    position: Vector3;
    bbox: number[];
    confidence: number;
    isMaskedOut: boolean = false;

    constructor(trackletId: string, time: Date, postion: Vector3, bbox: number[], confidence: number, isMaskedOut: boolean) {   
        this.trackletId = trackletId;
        this.time = time;
        this.position = postion;
        this.bbox = bbox;
        this.confidence = confidence;
        this.isMaskedOut = isMaskedOut;
    }

    static FromCsv(csvLine: string) : TrackletDataPoint {
        const values = csvLine.split(',');
        const trackletId = values[0];
        const time = dotNetTicksToDate(values[1]);
        const position = new Vector3(parseFloat(values[2]), parseFloat(values[3]), parseFloat(values[4]));
        const bbox = [ parseFloat(values[5]), parseFloat(values[6]), parseFloat(values[7]), parseFloat(values[8]) ];
        return new TrackletDataPoint(trackletId, time, position, bbox, 0, false);
    }
}