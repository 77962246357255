import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import TrackletLayer from "./TrackletLayer";
import SiteNavigator from "../models/SiteNavigator";
import TrackedObject from "../models/TrackedObject";

const TrackletsLayer: FunctionComponent<{
  siteNavigator: SiteNavigator
  selectTrackedObject: (trackedObject: TrackedObject) => void
  showDetailedTracklets: boolean
}> = observer(({ siteNavigator, selectTrackedObject: onTrackedObjectClicked, showDetailedTracklets }) => {


  return (
    <>
      {showDetailedTracklets && 
        siteNavigator.currentTracklets?.map((tracklet) => (
          <TrackletLayer key={`tracket-${tracklet.trackletId}`} tracklet={tracklet} siteNavigator={siteNavigator} selectTrackedObject={onTrackedObjectClicked} />
        ))}
    </>
  )

})


export default TrackletsLayer;


