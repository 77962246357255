import { ExtendedModel, model, prop } from "mobx-keystone";
import LayoutEntity from "./LayoutEntity";
import ScanAreaLayoutViewModel from "./ScanAreaLayoutViewModel";
import WallEntity from "./WallEntity";
import { maximumHeightForWallFeature } from "../../components-3d/WallsLayer";

export const findNextAvailableDoorName = (layoutViewModel: ScanAreaLayoutViewModel) => {
    const allDoorNames = new Set(
        layoutViewModel.scanAreaLayout.walls.flatMap(wall => wall.doors.map(door => door.name))
      );    
    
    let newWallName = "New Door";
    for (let i = 1; i <= allDoorNames.size + 1; i++) {
      if (!allDoorNames.has("Door" + i)) {
        newWallName = "Door" + i;
        break;
      }
    }
    return newWallName;
}

@model("SiteBionics/DoorEntity")
export default class DoorEntity extends ExtendedModel(LayoutEntity, {
    name: prop<string>().withSetter(),
    distanceAlongWall: prop<number>().withSetter(),
    width: prop<number>().withSetter(),
    height: prop<number>().withSetter(),
    matchWallSlant: prop<boolean>(false).withSetter(),
}) {

  updateDoorHeight(parentWall: WallEntity | undefined) {
    if (parentWall) {
      const newMaxHeight = maximumHeightForWallFeature(parentWall, this);
      this.setHeight(Math.min(this.height, newMaxHeight)); 
    }
  }
}