import { FC } from "react";
import { observer } from "mobx-react"
import { Alert, Box, List, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import SelectableListItemButton from "./SelectableListItemButton";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";

const AreaModelsAccordion: FC<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {
  return (
        <Box component="div" width={"100%"} display="flex">
            <Accordion disableGutters sx={{width: "100%"}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row">
                    <DoorSlidingOutlinedIcon fontSize="small"/>
                    <Box component="div" width={10}/>
                    <Typography>Area Models</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component='div' display="flex" flexDirection="column">
                      {layoutViewModel.scanAreaLayout.areaModels?.length ?
                        (<List dense={true} sx={{ bgcolor: 'background.paper', overflow: "clip" }} >
                            {layoutViewModel.scanAreaLayout.areaModels.map((areaModel) => (
                                <SelectableListItemButton key={areaModel.modelType} label={areaModel.modelType} ctx={areaModel}/>
                            ))}
                        </List>) : <Alert severity="warning">No scans.</Alert>
                      }
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
      )
    })
    
export default AreaModelsAccordion;

