import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Color3, Color4, LinesMesh, MeshBuilder, Vector3 } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import SiteNavigator from "../models/SiteNavigator";
import TrackedObject from "../models/TrackedObject";

const TrackedObjectLayer: FunctionComponent<{
  trackedObject: TrackedObject,
  siteNavigator: SiteNavigator,
  selectTrackedObject: (trackedObject: TrackedObject) => void
}> = observer(({ trackedObject, siteNavigator, selectTrackedObject }) => {

  const scene = useScene();
  const [currentPosition, setCurrentPosition] = useState<Vector3 | undefined>(undefined);
  
  useEffect(() => {
    if (siteNavigator.currentTime !== undefined) {
      let closestDataPoint = trackedObject.findClosestDataPoint(siteNavigator.currentTime.toDate());
      if (closestDataPoint) {
        let pos = new Vector3(closestDataPoint.position.x, closestDataPoint.position.y, closestDataPoint.position.z);
        setCurrentPosition(pos);
      } else {
        setCurrentPosition(undefined);
      }
    }
    else {
      setCurrentPosition(undefined);
    }
  }, [trackedObject, siteNavigator.currentTime]);


  useEffect(() => {
    if (!scene ||
      trackedObject.dataPoints.length === 0 ||
      (trackedObject !== siteNavigator.currentTrackedObject)
    ) return;

    
    var allLines: LinesMesh[] = [];

    // Define the points and colors for the line
    const points = trackedObject.dataPoints.map((dataPoint) => new Vector3(-dataPoint.position.x, dataPoint.position.y + 0.5, dataPoint.position.z));
    const color3 = Color3.Black();
    const color4 = new Color4(color3.r, color3.g, color3.b, 1)
    const colors = points.map(() => color4)
    // Create the line
    const lines = MeshBuilder.CreateLines("lines", {
      points,
      colors: colors,
      updatable: false
    }, scene);

    if (siteNavigator.currentScanAreaLayout)
      lines.setPreTransformMatrix(siteNavigator.currentScanAreaLayout.cameraSpaceToWorld);

    allLines.push(lines);


    // Cleanup function to remove the line from the scene
    return () => {
      allLines.forEach(line => line.dispose());
    };
  }, [scene, trackedObject, siteNavigator.currentTrackedObject, siteNavigator.currentScanAreaLayout, siteNavigator.currentScanAreaLayout?.cameraSpaceToWorld]);


  return (
    <>
      {currentPosition &&
        <>
          <transformNode name={`${trackedObject.trackedObjectId}`} position={new Vector3(-currentPosition.x, currentPosition.y, currentPosition.z)}>
            <sphere name={`${trackedObject.trackedObjectId}`} diameter={0.4} position={new Vector3(0, 1.5, 0)}>
              <standardMaterial name={`${trackedObject.trackedObjectId}-mat`}
                diffuseColor={trackedObject === siteNavigator.currentTrackedObject ? Color3.White() : trackedObject?.color} />
              <pointerDragBehavior
                dragAxis={new Vector3(0, 0, 0)} // using a trick here to disable dragging but enable events to simulate a click handler
                useObjectOrientationForDragging={true}
                onDragEndObservable={(_: any) => { selectTrackedObject(trackedObject!); }}
              />
            </sphere>
            <lines name="zaxis"
              points={[new Vector3(0, 0, 0), new Vector3(0, 1.5, 0)]}
              color={trackedObject?.color} />
          </transformNode>
    </>
      }

    </>
  )
})

const TrackedObjectsLayer: FunctionComponent<{
  siteNavigator: SiteNavigator
  selectTrackedObject: (trackedObject: TrackedObject) => void
  showStationaryTrackledObjects: boolean
}> = observer(({ siteNavigator, selectTrackedObject: onTrackedObjectClicked, showStationaryTrackledObjects }) => {


  return (
    <>
    {
        siteNavigator.currentTrackedObjects?.filter(to => showStationaryTrackledObjects || !to.isStationary).map((trackedObject) => (
          <TrackedObjectLayer key={`trackedobject-${trackedObject.trackedObjectId}`} trackedObject={trackedObject} siteNavigator={siteNavigator} selectTrackedObject={onTrackedObjectClicked} />
        ))
    }
    </>
  )

})


export default TrackedObjectsLayer;


