import React from "react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { IconButton, Select, MenuItem, Button, FormControl, InputLabel, Typography, FormControlLabel, Checkbox, Alert, Box, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { convertTimezoneOnly, minutesToLabel } from "../util/Time";
import Site from "../models/Site";
import DownloadIcon from '@mui/icons-material/Download';
import utc from 'dayjs/plugin/utc';
import { saveAs } from 'file-saver';
import { sha224 } from "js-sha256";
import duration, { Duration } from "dayjs/plugin/duration";
import Shift from '../models/Shift';
import { SiteEvent } from '../models/SiteEvent';
import CustomTable, { CellData, ratioColors, blueColors, getColorFromValue } from "./CustomTable";
import ShiftTable from "./ShiftTable";
import useQueryState from "../hooks/useQueryState";
import { useSiteNavigator } from "../pages/SitePage";
import OccupancyTable from "./OccupancyTable";
import TransactionTable from "./TransactionTable";

dayjs.extend(duration);
dayjs.extend(utc);

const OccupancyChart: React.FC<{ date: Dayjs, site: Site }> = observer(({ date, site }) => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();

  // Raw data for the tagged object counts
  const [taggedObjectCounts, setTaggedObjectCounts] = useState<TaggedObjectCounts[] | null>(null);
  const [isTaggedObjectCountsLoading, setIsTaggedObjectCountsLoading] = useState<boolean>(false);

  // Raw shift data
  const [shiftData, setShiftData] = useState<Shift[] | null>(null);
  const [isShiftDataLoading, setIsShiftDataLoading] = useState<boolean>(false);

  // Raw transaction data
  const [transactionEventData, setTransactionEventData] = useState<SiteEvent[] | null>(null);
  const [isTransactionEventDataLoading, setIsTransactionEventDataLoading] = useState<boolean>(false);

  // Prevents double fetch tagged objects
  const hasFetchedData = useRef(false);  
  
  // Variables that control the display
  const [displayHours, setDisplayHours] = useState<string>("Open");
  const [displayDataLeft, setDisplayDataLeft] = useState<string>("Average");
  const [displayDataRight, setDisplayDataRight] = useState<string>("None");
  const [granularity, setGranularity] = useState<number>(60);
  
  // Date in the site's timezone
  const siteDate = convertTimezoneOnly(date.startOf('day'), site.timeZone);

  // Fetch the tagged object counts and shift data for the week
  useEffect(() => {

    // Fetch the tagged object counts for the week
    if (!isTaggedObjectCountsLoading && !hasFetchedData.current) {
      setIsTaggedObjectCountsLoading(true);
      hasFetchedData.current = true; // Set the flag immediately to prevent multiple fetches

      siteBionicsApplication.service.fetchTaggedObjectCountsAsync(site, siteDate, siteDate.add(1, 'week'), "00:15", "").then((taggedObjectCounts) => {
        setTaggedObjectCounts(taggedObjectCounts);
        setIsTaggedObjectCountsLoading(false);
      }).catch(() => {
        hasFetchedData.current = false; // Reset the flag if the fetch fails
        setIsTaggedObjectCountsLoading(false);
      });
    }

    // Fetch the shift data for the week
    setIsShiftDataLoading(true);

    siteBionicsApplication.service.fetchShiftsAsync(site, siteDate, siteDate.add(1, 'week')).then((shiftData) => {
      setShiftData(shiftData);
      setIsShiftDataLoading(false);
    }).catch(() => {
      setIsShiftDataLoading(false);
    });

    // Fetch the transaction data for the week
    setIsTransactionEventDataLoading(true);
    siteBionicsApplication.service.fetchSiteEventsAsync(site, siteDate, siteDate.add(1, 'week'), 'Transaction').then((transactionEventData) => {
      setTransactionEventData(transactionEventData);
      setIsTransactionEventDataLoading(false);
    }).catch(() => {
      setIsTransactionEventDataLoading(false);
    });

  }, [date]);

  if (isShiftDataLoading || isTaggedObjectCountsLoading || isTransactionEventDataLoading) {
    return (
      <Box component="div" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box component="div" display="flex" flexDirection="column" overflow="hidden">
      <Box component="div" style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginTop: 5, marginLeft: 10 }}>
        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="displayhours-label">Hours</InputLabel>
          <Select
            labelId="displayhours-label"
            value={displayHours}
            onChange={(e) => setDisplayHours(e.target.value)}
            label="Hours"
          >
            <MenuItem value="Open">Open Hours</MenuItem>
            <MenuItem value="Staffed">Staffed Hours</MenuItem>
            <MenuItem value="All">All Hours</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="granularity-label">Granularity</InputLabel>
          <Select
            labelId="granularity-label"
            value={granularity}
            onChange={(e) => setGranularity(e.target.value as number)}
            label="Granularity"
          >
            <MenuItem value={60}>By Hour</MenuItem>
            <MenuItem value={30}>By 30 Minutes</MenuItem>
            <MenuItem value={15}>By 15 Minutes</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="displaydataleft-label">Display Left</InputLabel>
          <Select
            labelId="displaydataleft-label"
            value={displayDataLeft}
            onChange={(e) => setDisplayDataLeft(e.target.value)}
            label="Display"
          >
            <MenuItem value="Average">Average</MenuItem>
            <MenuItem value="Min">Min</MenuItem>
            <MenuItem value="Max">Max</MenuItem>
            <MenuItem value="MinAvgMax">Min/Avg/Max</MenuItem>
            <MenuItem value="Ratio">Ratio (Occupancy/Staff)</MenuItem>
            <MenuItem value="Staffing">Staffing</MenuItem>
            <MenuItem value="Transactions">Transactions</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="displaydataright-label">Display Right</InputLabel>
          <Select
            labelId="displaydataright-label"
            value={displayDataRight}
            onChange={(e) => setDisplayDataRight(e.target.value)}
            label="Display"
          >
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="Average">Average</MenuItem>
            <MenuItem value="Min">Min</MenuItem>
            <MenuItem value="Max">Max</MenuItem>
            <MenuItem value="MinAvgMax">Min/Avg/Max</MenuItem>
            <MenuItem value="Ratio">Ratio (Occupancy/Staff)</MenuItem>
            <MenuItem value="Staffing">Staffing</MenuItem>
            <MenuItem value="Transactions">Transactions</MenuItem>
          </Select>
        </FormControl>
        
      </Box>

      <Box component="div" display="flex" width="100%" overflow="auto">
        <Box component="div" sx={{ width: displayDataRight === "None" ? '100%' : '50%' }}>
          {displayDataLeft === "Staffing" &&
            <ShiftTable shiftData={shiftData!} granularity={granularity} displayHours={displayHours} siteDate={siteDate} site={site} />
          }
          {displayDataLeft === "Transactions" &&
            <TransactionTable transactionEventData={transactionEventData!} shiftData={shiftData!} granularity={granularity} displayHours={displayHours} siteDate={siteDate} site={site} />
          }
          {displayDataLeft !== "Staffing" && displayDataLeft !== "Transactions" &&
            <OccupancyTable taggedObjectCounts={taggedObjectCounts!} shiftData={shiftData!} granularity={granularity} displayHours={displayHours} displayData={displayDataLeft} siteDate={siteDate} site={site} />
          }
        </Box>

        {displayDataRight !== "None" &&
          <Box component="div" sx={{ width: '50%' }}>
            {displayDataRight === "Staffing" &&
              <ShiftTable shiftData={shiftData!} granularity={granularity} displayHours={displayHours} siteDate={siteDate} site={site} />
            }
            {displayDataRight === "Transactions" &&
              <TransactionTable transactionEventData={transactionEventData!} shiftData={shiftData!} granularity={granularity} displayHours={displayHours} siteDate={siteDate} site={site} />
            }
            {displayDataRight !== "Staffing" && displayDataRight !== "Transactions" &&
              <OccupancyTable taggedObjectCounts={taggedObjectCounts!} shiftData={shiftData!} granularity={granularity} displayHours={displayHours} displayData={displayDataRight} siteDate={siteDate} site={site} />
            }
          </Box>
        }        
      </Box>
    </Box>
  );

});

export default OccupancyChart;
