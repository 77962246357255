import { ExtendedModel, idProp, model, prop } from "mobx-keystone";
import Vector3Model from "./Vector3Model";
import LayoutEntity from "./LayoutEntity";
import { ModelType } from "../ModelType";
import PoseModel from "./PoseModel";


@model("SiteBionics/AreaModelEntity")
export default class AreaModelEntity extends ExtendedModel(LayoutEntity, {
    pose: prop<PoseModel>(PoseModel.identity).withSetter(),
    scale: prop<number>(1).withSetter(),
    scanId: prop<string>("").withSetter(),
    modelType: prop<string>("").withSetter(),  // Lidar, Photogrammetry, GaussianSplats, Floorplan, etc.
    usePoseFromLidar: prop<boolean>(false).withSetter(),
    clipHeight: prop<number>(3).withSetter(),
}) {
}