import { FC, FunctionComponent, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react"
import { Alert, Box, Button, List, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import CornerEntity from "../models/layout/CornerEntity";
import ScanAreaLayoutViewModel, { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";
import WallEntity from "../models/layout/WallEntity";
import { findNextAvailableWallName } from "../models/layout/WallEntity";
import Vector3Model from "../models/layout/Vector3Model";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import DoorEntity from "../models/layout/DoorEntity";
import WindowEntity from "../models/layout/WindowEntity";
import { Vector3 } from "@babylonjs/core";

export const handleAddWall = (layoutViewModel: ScanAreaLayoutViewModel, wallToAddTo: undefined | WallEntity, cornerToAddFrom: undefined | CornerEntity) => {
  let originalStartCornerPosition = new Vector3Model({x:0, y:layoutViewModel.scanAreaLayout.defaultWallHeight, z:0});
  let originalEndCornerPosition = new Vector3Model({x:1, y:layoutViewModel.scanAreaLayout.defaultWallHeight, z:1});

  if (cornerToAddFrom && wallToAddTo) {
    layoutViewModel.setShowSpecificCornerId("");
    originalStartCornerPosition = new Vector3Model({x:cornerToAddFrom.position.x, y:cornerToAddFrom.position.y, z:cornerToAddFrom.position.z});
    let addFromWallOtherCorner = cornerToAddFrom.isStartCorner ? wallToAddTo.endCorner : wallToAddTo.startCorner;

    let dX = cornerToAddFrom.position.x - addFromWallOtherCorner.position.x;
    let dY = cornerToAddFrom.position.y - addFromWallOtherCorner.position.y;
    let dZ = cornerToAddFrom.position.z - addFromWallOtherCorner.position.z;

    const direction = new Vector3(dX, dY, dZ).normalize();

    originalEndCornerPosition = new Vector3Model({
      x: originalStartCornerPosition.x + direction.x,
      y: cornerToAddFrom.position.y,
      z: originalStartCornerPosition.z + direction.z});
  } 
  
  let newWallName = findNextAvailableWallName(layoutViewModel);

  let newStartCorner = new CornerEntity({
    name: newWallName + "-start",
    position: originalStartCornerPosition,
    isStartCorner: true,
  });

  let newEndCorner = new CornerEntity({
    name: newWallName + "-end",
    position: originalEndCornerPosition,
    isStartCorner: false,
  });

  let wall = new WallEntity({name: newWallName, startCorner: newStartCorner, endCorner: newEndCorner, doors: new Array<DoorEntity>, windows: new Array<WindowEntity>});
  layoutViewModel.scanAreaLayout.addWall(wall);

  if (cornerToAddFrom) {
    newStartCorner.addLinkedCorner(cornerToAddFrom);
    cornerToAddFrom.addLinkedCorner(newStartCorner);
  }

  layoutViewModel.selectEntity(wall);

}

const WallTreeItem: FunctionComponent<{ wall: WallEntity }> = observer(({ wall }) => {
  const layoutViewModel = useScanAreaLayoutViewModel();

  return (
    <TreeItem
      key={wall.id}
      itemId={wall.id}
      label={wall.name?.length > 0 ? wall.name : "Unnamed Wall"}
      onClick={() => {
        layoutViewModel?.selectEntity(wall);
      }}
    >
      {wall.doors?.map((door) => (
        <TreeItem
          key={door.id}
          itemId={door.id}
          label={door.name}
          onClick={() => {
            layoutViewModel?.selectEntity(door);
          }}
        />
      ))}

      {wall.windows?.map((window) => (
        <TreeItem
          key={window.id}
          itemId={window.id}
          label={window.name}
          onClick={() => {
            layoutViewModel?.selectEntity(window);
          }}
        />
      ))}
    </TreeItem>
  );
});

const FloorPlanAccordion: FC<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {
  const [selectedItems, setSelectedItems] = useState<string>("");
  const [expanded, setExpanded] = useState<string[]>([]);

  const floorPlanTreeRef = useTreeViewApiRef();
  
  //Watch for changes to doors
  const [lastWallsStateDoors, setLastWallsStateDoors] = useState(new Map());
  useEffect(() => {
    const currentDoorsState = new Map();

    layoutViewModel.scanAreaLayout.walls.forEach((wall) => {
      currentDoorsState.set(wall.id, wall.doors.length);

      const lastDoorCount = lastWallsStateDoors.get(wall.id) || 0;
      if (wall.doors.length > lastDoorCount) {
        addItemToExpanded(wall.id);
      }
    });

    setLastWallsStateDoors(currentDoorsState);
  }, [layoutViewModel.scanAreaLayout.walls.map(wall => wall.doors.length).join(',')]);

    //Watch for changes to windows
    const [lastWallsStateWindows, setLastWallsStateWindows] = useState(new Map());
    useEffect(() => {
      const currentWindowsState = new Map();
  
      layoutViewModel.scanAreaLayout.walls.forEach((wall) => {
        currentWindowsState.set(wall.id, wall.windows.length);
  
        const lastWindowCount = lastWallsStateWindows.get(wall.id) || 0;
        if (wall.windows.length > lastWindowCount) {
          addItemToExpanded(wall.id);
        }
      });
  
      setLastWallsStateWindows(currentWindowsState);
    }, [layoutViewModel.scanAreaLayout.walls.map(wall => wall.windows.length).join(',')]);

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      let selectedWall = layoutViewModel.scanAreaLayout.walls.filter( w => w.id === itemId);
      layoutViewModel?.selectEntity(selectedWall[0]);
      setSelectedItems(itemId);
    }
  };

  const addItemToExpanded = (itemId: string) => {
    setExpanded((prevExpanded) => {
      // Add the item only if it is not already in the expanded list
      if (!prevExpanded.includes(itemId)) {
        return [...prevExpanded, itemId];
      }
      return prevExpanded;
    });
  }

  const handleExpansionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    if (layoutViewModel.selectedEntity?.id === itemId) {
      isSelected ? addItemToExpanded(itemId) : setExpanded(expanded.filter(id => id !== itemId));
    }
  };

  function handleRemoveWallOrChild(): void {
    if (layoutViewModel.selectedEntity instanceof WallEntity) {
      layoutViewModel.scanAreaLayout.removeWall(layoutViewModel.selectedEntity);
      layoutViewModel.selectEntity(undefined);
    } else if (layoutViewModel.selectedEntity instanceof DoorEntity) {
      for (const wall of layoutViewModel.scanAreaLayout.walls) {
        if (wall.doors.includes(layoutViewModel.selectedEntity)){ 
          wall.removeDoorFromWall(layoutViewModel.selectedEntity);
          layoutViewModel.selectEntity(wall);
          break;
        }
      }
    } else if (layoutViewModel.selectedEntity instanceof WindowEntity) {
      for (const wall of layoutViewModel.scanAreaLayout.walls) {
        if (wall.windows.includes(layoutViewModel.selectedEntity)){ 
          wall.removeWindowFromWall(layoutViewModel.selectedEntity);
          layoutViewModel.selectEntity(wall);
          break;
        }
      }
    }
  }

  useEffect(() => {
    if (!(layoutViewModel.selectedEntity instanceof WallEntity || layoutViewModel.selectedEntity instanceof DoorEntity || layoutViewModel.selectedEntity instanceof WindowEntity)) {
      setSelectedItems("");
    } else {
      setSelectedItems(layoutViewModel.selectedEntity.id);
    }


  }, [layoutViewModel.selectedEntity]);

    return (
        <Box component="div" width={"100%"} display="flex">
            <Accordion disableGutters sx={{width: "100%"}}
                  expanded={layoutViewModel.showWalls ?? true} 
                  onChange={(e, expanded) => {layoutViewModel.setShowWalls(expanded);}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row">
                    <DoorSlidingOutlinedIcon fontSize="small"/>
                    <Box component="div" width={10}/>
                    <Typography>Floorplan</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component='div' display="flex" flexDirection="column">
                      {layoutViewModel.scanAreaLayout.walls?.length ?
                        <SimpleTreeView apiRef={floorPlanTreeRef} onItemSelectionToggle={handleItemSelectionToggle} selectedItems={selectedItems} expandedItems={expanded} onItemExpansionToggle={handleExpansionToggle}>
                          {layoutViewModel.scanAreaLayout.walls.map((wall) => (
                            <WallTreeItem wall={wall}/>
                          ))}
                        </SimpleTreeView>
                     : (<Alert severity="warning">No walls.</Alert>)}

                      <Box component='div' display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button id="removeWallButton"
                          disabled={!(layoutViewModel.selectedEntity instanceof WallEntity) && !(layoutViewModel.selectedEntity instanceof DoorEntity) && !(layoutViewModel.selectedEntity instanceof WindowEntity)}
                          onClick={handleRemoveWallOrChild}>
                          remove wall
                        </Button>
                        <Button id="addWallButton" onClick={() => handleAddWall(layoutViewModel, undefined, undefined)}>add wall</Button>
                        <Button id="deleteFloorPlan"
                          disabled={layoutViewModel.scanAreaLayout.walls.length === 0}
                          onClick={() => {layoutViewModel.scanAreaLayout.deleteFloorPlan()}}>
                          delete all walls
                        </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
      )
    })
    
export default FloorPlanAccordion;

