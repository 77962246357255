import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Scene, Engine, useScene} from 'react-babylonjs'
import '@babylonjs/loaders'
import "@babylonjs/loaders/glTF";
import { Color3, Color4, Quaternion} from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import CamerasLayer from "./CamerasLayer";
import SiteModelLayer from "./SiteModelLayer";
import TrackletsLayer from "./TrackletsLayer";
import SiteNavigator from "../models/SiteNavigator";
import CameraPose from "../models/CameraPose";
import CameraCoverageLayer from "./CameraCoverageLayer";
import ScanAreaLayoutViewModel, { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";
import AreaModelEntity from "../models/layout/AreaModelEntity";
import { ModelType } from "../models/ModelType";
import PointersLayer from "./PointersLayer";
import { Box } from "@mui/material";
import WallsLayer, { drawWallLines } from "./WallsLayer";
import { LinesMesh } from "@babylonjs/core";
import ScanAreaLayout from "../models/layout/ScanAreaLayout";


const SiteViewerScene : FunctionComponent<{siteNavigator: SiteNavigator, scanAreaLayout: ScanAreaLayout, showCameras: boolean, children?: React.ReactNode}> = observer(({siteNavigator, scanAreaLayout, showCameras, children}) => {
  const [areaModelEntity, setAreaModelEntity] = useState<AreaModelEntity | undefined>(undefined);
  const [areaModelEntityToUse, setAreaModelEntityToUse] = useState<AreaModelEntity | undefined>(undefined);

  useEffect(() => {

    if (scanAreaLayout && siteNavigator.currentScan) {
      const areaModelEntityLidar = scanAreaLayout.areaModels.find(areaModel => areaModel.modelType === ModelType[ModelType.Lidar]);
      const areaModelEntity = scanAreaLayout.areaModels.find(areaModel => areaModel.modelType === ModelType[siteNavigator.currentModelType]);
      setAreaModelEntity(areaModelEntity);
      if (areaModelEntity === undefined || areaModelEntity.usePoseFromLidar === false) {
        setAreaModelEntityToUse(areaModelEntity);  
      } else {
        setAreaModelEntityToUse(areaModelEntityLidar);
      }
    }
  }, [siteNavigator.currentScan, siteNavigator.currentModelType, scanAreaLayout, areaModelEntity?.usePoseFromLidar]);

  let scene = useScene()
  const [allLineMeshes, setAllLineMeshes] = useState<LinesMesh[]>([]);
  
  useEffect(() => {
    const disposeLines = () => {
      allLineMeshes.forEach(lineMesh => {lineMesh.dispose()});
      setAllLineMeshes([]);
    }

    if (siteNavigator.overlayFloorPlan && siteNavigator.currentScanAreaLayout) {
      const scanAreaLayout = siteNavigator.currentScanAreaLayout;
      siteNavigator.currentScanAreaLayout.walls.forEach(wall => {
        //TODO: Update pose here
        const {wallLineSystemMap, doorLineSystemMap, windowLineSystemMap} = drawWallLines(wall, scanAreaLayout, scene, undefined);

        const allNewLineMeshes: LinesMesh[] = [];
        wallLineSystemMap.forEach((linesMesh) => {
          allNewLineMeshes.push(linesMesh);
        });

        doorLineSystemMap.forEach((linesMesh) => {
          allNewLineMeshes.push(linesMesh);
        });

        windowLineSystemMap.forEach((linesMesh) => {
          allNewLineMeshes.push(linesMesh);
        });

        setAllLineMeshes(allLineMeshes => [...allLineMeshes, ...allNewLineMeshes]);
      });

    } else {
      disposeLines();
    }
       
            
    return () => {
      disposeLines();
      //siteNavigator.setCurrentScanAreaLayout(undefined);
    };

  }, [siteNavigator.overlayFloorPlan, siteNavigator.currentScanAreaLayout])


  return (
   <>
      <Box component="div" overflow="hidden" sx={{ display: 'flex', flex: 1, backgroundColor: '#0000FF' }}>
        <Engine antialias canvasId="babylon-js" renderOptions={{whenVisibleOnly: true,}}>
            <Scene clearColor={new Color4(.5,.5,1,1)}>

              {true &&
              <arcRotateCamera position={new Vector3(0, 5, -5)} name="Camera1" radius={1} alpha={Math.PI / 2} beta={Math.PI / 2} target={new Vector3(0,0,0) }
                  inertia={0} wheelDeltaPercentage={.05} panningSensibility={500} panningInertia={0} maxZ={1000} minZ={.1} setActiveOnSceneIfNoneActive/>
              };

              <hemisphericLight name="light1" intensity={0.7} direction={new Vector3(0, 1, 0)}/>
              
              <lines name="zaxis" points={[new Vector3(0, 0, 0), new Vector3(0, 0, 2)]} color={Color3.Blue()} />
              <lines name="yaxis" points={[new Vector3(0, 0, 0), new Vector3(0, 2, 0)]} color={Color3.Green()} />
              <lines name="xaxis" points={[new Vector3(0, 0, 0), new Vector3(2, 0, 0)]} color={Color3.Red()} />

              <transformNode name="modelPosition" position={areaModelEntityToUse?.pose.position.asLHVector3 ?? Vector3.Zero()}>
                <transformNode name="modelRotation" rotationQuaternion={areaModelEntityToUse?.pose.orientation.asLHQuaternion ?? Quaternion.Identity()}>
                  <transformNode name="modelScale" scaling={new Vector3(areaModelEntityToUse?.scale ?? 1, areaModelEntityToUse?.scale ?? 1, areaModelEntityToUse?.scale ?? 1)}>
                    {areaModelEntity &&
                      <SiteModelLayer siteNavigator={siteNavigator} clipHeight={areaModelEntity.clipHeight} />
                    }
                    {showCameras &&
                      <CamerasLayer scanAreaLayout={scanAreaLayout}/>}
                    {children}
              </transformNode>
                </transformNode>
              </transformNode>
            </Scene>
        </Engine>
      </Box>
    </>
  )
})

export default SiteViewerScene;


