import { ExtendedModel, model, prop } from "mobx-keystone";
import LayoutEntity from "./LayoutEntity";
import ScanAreaLayoutViewModel from "./ScanAreaLayoutViewModel";
import WallEntity from "./WallEntity";
import { maximumHeightForWallFeature } from "../../components-3d/WallsLayer";

export const findNextAvailableWindowName = (layoutViewModel: ScanAreaLayoutViewModel) => {
    const allWindowNames = new Set(
        layoutViewModel.scanAreaLayout.walls.flatMap(wall => wall.windows.map(window => window.name))
      );    
    
    let newWallName = "New Window";
    for (let i = 1; i <= allWindowNames.size + 1; i++) {
      if (!allWindowNames.has("Window" + i)) {
        newWallName = "Window" + i;
        break;
      }
    }
    return newWallName;
}

@model("SiteBionics/WindowEntity")
export default class WindowEntity extends ExtendedModel(LayoutEntity, {
    name: prop<string>().withSetter(),
    distanceAlongWall: prop<number>().withSetter(),
    width: prop<number>().withSetter(),
    verticalHeight: prop<number>().withSetter(),
    windowHeight: prop<number>().withSetter(),
    matchWallSlant: prop<boolean>(false).withSetter(),
}) {
    updateWindowHeight(parentWall: WallEntity | undefined) {
      if (parentWall) {
        const newMaxHeight = maximumHeightForWallFeature(parentWall, this);
        this.setWindowHeight(Math.min(this.windowHeight, newMaxHeight));
        this.setVerticalHeight(Math.min(this.verticalHeight, newMaxHeight - this.windowHeight));
      }
    }
}
