import { toZonedTime } from 'date-fns-tz';
import { SiteEvent } from './SiteEvent';

export interface TransactionInterval {
  intervalStart: Date;
  intervalEnd: Date;
  totalOrderCount: number;
  totalItemCount: number;
  totalOrderAmount: number;
  totalOrderDiscount: number;
  averageOrderCount: number;
  averageItemCount: number;
  averageOrderAmount: number;
  averageOrderDiscount: number;
}

export interface TransactionAnalysisResults {
  intervals: TransactionInterval[];
  totalOrderCount: number;
  totalItemCount: number;
  totalOrderAmount: number;
  totalOrderDiscount: number;
  averageOrderCount: number;
  averageItemCount: number;
  averageOrderAmount: number;
  averageOrderDiscount: number;
}

export class TransactionAnalyzer {
  siteEvents: SiteEvent[];

  constructor(siteEvents: SiteEvent[]) {
    this.siteEvents = siteEvents;
  }

  analyze(startTime: Date, endTime: Date, intervalMinutes: number, timeZone: string): TransactionAnalysisResults {
    const intervals: TransactionInterval[] = [];
    const intervalMillis = intervalMinutes * 60 * 1000;

    // Convert start and end times to the specified timezone
    const start = toZonedTime(startTime, timeZone);
    const end = toZonedTime(endTime, timeZone);

    // Initialize interval ranges
    for (let time = start.getTime(); time < end.getTime(); time += intervalMillis) {
      intervals.push({
        intervalStart: new Date(time),
        intervalEnd: new Date(time + intervalMillis),
        totalOrderCount: 0,
        totalItemCount: 0,
        totalOrderAmount: 0,
        totalOrderDiscount: 0,
        averageOrderCount: 0,
        averageItemCount: 0,
        averageOrderAmount: 0,
        averageOrderDiscount: 0,
        ordersMap: new Map<string, { itemCount: number; orderAmount: number; orderDiscount: number }>(), // Initialize orders map
      } as TransactionInterval & { ordersMap: Map<string, { itemCount: number; orderAmount: number; orderDiscount: number }> });
    }

    // Process siteEvents into intervals
    for (const siteEvent of this.siteEvents) {

      if (siteEvent.eventType !== "Transaction" || siteEvent.eventItems === null || siteEvent.eventItems === undefined) continue;

      // Convert transaction time to the specified timezone
      const transactionTime = toZonedTime(siteEvent.eventDate, timeZone);

      for (const interval of intervals) {
        const intervalStart = interval.intervalStart;
        const intervalEnd = interval.intervalEnd;

        if (transactionTime >= intervalStart && transactionTime < intervalEnd) {
          const ordersMap = (interval as any)['ordersMap'];

          let itemCount = 0;
          let orderAmount = 0;
          let orderDiscount = 0;

          for (const eventItem of siteEvent.eventItems) {
            const quantity = eventItem.additionalProperties!['itemQuantity'];
            itemCount += quantity;
            orderAmount += eventItem.additionalProperties!['itemAmount'] * quantity;
            orderDiscount += eventItem.additionalProperties!['itemDiscount'] * quantity;
          }
          
          ordersMap.set(siteEvent.eventId, {
            itemCount: itemCount,
            orderAmount: orderAmount,
            orderDiscount: orderDiscount,
          });

          // Update interval totals
          interval.totalItemCount += itemCount;
          interval.totalOrderAmount += orderAmount;
          interval.totalOrderDiscount += orderDiscount;
        }
      }
    }

    // Finalize interval statistics
    for (const interval of intervals) {
      const ordersMap = (interval as any)['ordersMap'] as Map<string, { itemCount: number; orderAmount: number; orderDiscount: number }>;
      interval.totalOrderCount = ordersMap.size;
      interval.averageItemCount = 
        interval.totalOrderCount > 0
          ? interval.totalItemCount / interval.totalOrderCount
          : 0;
      interval.averageOrderAmount =
        interval.totalOrderCount > 0
          ? interval.totalOrderAmount / interval.totalOrderCount
          : 0;
      interval.averageOrderDiscount =
        interval.totalOrderCount > 0
          ? interval.totalOrderDiscount / interval.totalOrderCount
          : 0;

      // Clean up unnecessary orders map for return
      delete (interval as any)['ordersMap'];
    }

    // Calculate overall averages
    const overall = intervals.reduce(
      (acc, interval) => {
        acc.totalOrderCount += interval.totalOrderCount;
        acc.totalItemCount += interval.totalItemCount;
        acc.totalOrderAmount += interval.totalOrderAmount;
        acc.totalOrderDiscount += interval.totalOrderDiscount;
        acc.intervalCount++;
        return acc;
      },
      {
        totalOrderCount: 0,
        totalItemCount: 0,
        totalOrderAmount: 0,
        totalOrderDiscount: 0,
        intervalCount: 0,
      }
    );

    const transactionAnalysisResults: TransactionAnalysisResults = {
      intervals,
      totalOrderCount: overall.totalOrderCount,
      totalItemCount: overall.totalItemCount,
      totalOrderAmount: overall.totalOrderAmount,
      totalOrderDiscount: overall.totalOrderDiscount,
      averageOrderCount: overall.totalOrderCount / overall.intervalCount,
      averageItemCount: overall.totalItemCount / overall.totalOrderCount,
      averageOrderAmount: overall.totalOrderAmount / overall.totalOrderCount,
      averageOrderDiscount: overall.totalOrderDiscount / overall.totalOrderCount,
    };

    return transactionAnalysisResults;
  }
}
