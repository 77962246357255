import { FunctionComponent, useEffect, useState } from "react";
import { Typography, Select, IconButton, Button, MenuItem, FormControl, InputLabel, Alert, Box, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import { convertTimezoneOnly, minutesToLabel } from "../util/Time";
import { observer } from "mobx-react";
import { SiteEvent } from "../models/SiteEvent";
import Shift from "../models/Shift";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { toZonedTime } from 'date-fns-tz';
import Site from "../models/Site";
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import CustomTable, { CellData, blueColors, getColorFromValue } from "./CustomTable";
import TransactionTable from "./TransactionTable";

function getMinMaxStaffedTime(shifts: Shift[]): [number, number] {
  let minStaffedTime = Number.MAX_SAFE_INTEGER;
  let maxStaffedTime = Number.MIN_SAFE_INTEGER;

  for (const shift of shifts) {
    const start = new Date(shift.startTime);
    const end = new Date(shift.endTime);

    const startMinutes = start.getHours() * 60 + start.getMinutes();
    const endMinutes = end.getHours() * 60 + end.getMinutes();

    minStaffedTime = Math.min(minStaffedTime, startMinutes);
    maxStaffedTime = Math.max(maxStaffedTime, endMinutes);
  }

  return [minStaffedTime, maxStaffedTime];
}

const TransactionChart: React.FC<{ date: Dayjs, site: Site }> = observer(({ date, site }) => {

  const siteBionicsApplication = useSiteBionicsApplication();

  // Transaction data as returned from the server
  const [shiftData, setShiftData] = useState<Shift[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [transactionEventData, setTransactionEventData] = useState<SiteEvent[]>([]);

  // Controls the display of the data
  const [granularity, setGranularity] = useState<number>(60);
  const [displayHours, setDisplayHours] = useState<string>("Open");

  // Convert the date to the site's timezone
  const siteDate = convertTimezoneOnly(date.startOf('day'), site.timeZone);


  // Get the shift data from the server
  useEffect(() => {
    setIsLoading(true);
    siteBionicsApplication.service.fetchShiftsAsync(site, siteDate.utc(), siteDate.add(1, 'week').utc()).then((shifts) => {
      setShiftData(shifts);

      
      siteBionicsApplication.service.fetchSiteEventsAsync(site, siteDate.utc(), siteDate.add(1, 'week').utc(), "Transaction").then((transactionEventData) => {
        setTransactionEventData(transactionEventData);
        setIsLoading(false);
      });
    });
  }, [date]);

  if (isLoading) {
    return (
      <Box component="div" display="flex">
        <CircularProgress />
      </Box>
    );
  }

  if (transactionEventData.length === 0) {
    return (
      <Box component="div" display="flex">
        <Alert severity="warning">No data for this date.</Alert>
      </Box>
    )
  }

  return (
    <Box component="div" display="flex" flexDirection="column" overflow="hidden">
      <Box component="div" style={{ display: 'flex', alignItems: 'center', marginBottom: 5, marginTop: 5, marginLeft: 10 }}>
        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="displayhours-label">Hours</InputLabel>
          <Select
            labelId="displayhours-label"
            value={displayHours}
            onChange={(e) => setDisplayHours(e.target.value)}
            label="Hours"
          >
            <MenuItem value="Open">Open Hours</MenuItem>
            <MenuItem value="Staffed">Staffed Hours</MenuItem>
            <MenuItem value="All">All Hours</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="granularity-label">Granularity</InputLabel>
          <Select
            labelId="granularity-label"
            value={granularity}
            onChange={(e) => setGranularity(e.target.value as number)}
            label="Granularity"
          >
            <MenuItem value={60}>By Hour</MenuItem>
            <MenuItem value={30}>By 30 Minutes</MenuItem>
            <MenuItem value={15}>By 15 Minutes</MenuItem>
          </Select>
        </FormControl>        
      </Box>

      <Box component="div" display="flex" width="100%" overflow="auto">
        <Box component="div" sx={{ width: '100%' }}>
          <TransactionTable transactionEventData={transactionEventData} shiftData={shiftData}   granularity={granularity} displayHours={displayHours} siteDate={siteDate} site={site} />                   
        </Box>
      </Box>
    </Box>
  );
});

export default TransactionChart;
