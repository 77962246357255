import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Box, Checkbox, FormControlLabel, Slider, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import DoorEntity from "../models/layout/DoorEntity";
import { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";
import { maximumHeightForWallFeature } from "../components-3d/WallsLayer";


const DoorProperties: FunctionComponent<{ door: DoorEntity }> = observer(({ door }) => {
    let layoutViewModel = useScanAreaLayoutViewModel();
    let parentWall = undefined;
    const [wallIsHorizontal, setWallIsHorizontal] = useState<boolean>(true);
    
    if (layoutViewModel) {
      parentWall = layoutViewModel.scanAreaLayout.walls.filter((wall) => wall.doors.includes(door))[0]
    }

    useEffect(() => {
      if (parentWall) {
        const horizontal = (parentWall.startCorner.position.y === parentWall.endCorner.position.y)
        setWallIsHorizontal(horizontal);
      }
    }, [parentWall?.startCorner.position.y, parentWall?.endCorner.position.y]); 

    return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <DoorSlidingOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Door Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {door.name}

          <form>
            <TextField
              label="Name"
              value={door.name}
              onChange={(e) => {door.setName(e.target.value)}}
              fullWidth
              margin="normal"
            />
            <Typography variant="caption">
                Horizontal Position
            </Typography>
            <Slider
              size="small"
              value={door.distanceAlongWall}
              min={0}
              max={1}
              step={0.01}
              valueLabelDisplay="auto"
              onChange={(e, v) => {
                door.setDistanceAlongWall(v as number);
                door.updateDoorHeight(parentWall);
              }}
            />
            <TextField
              label="Width"
              value={door.width}
              type="number"
              onChange={(e) => {
                let target = parseFloat(e.target.value);
                const maxWidth = 10

                //TODO: Dynamically Set?
                if (target > maxWidth) {
                  e.target.value = '' + maxWidth;
                }

                if (target >= 0 && target <= maxWidth) {
                    door.setWidth(target);
                    door.updateDoorHeight(parentWall)
                }
              }}
              inputProps={{
                step: 0.25,
              }}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Height"
              value={door.height}
              type="number"
              onChange={(e) => {
                let target = parseFloat(e.target.value);
                if (parentWall) {
                  const maxHeight = maximumHeightForWallFeature(parentWall, door);

                  if (layoutViewModel && target > maxHeight) {
                    e.target.value = '' + maxHeight;
                  }

                  if (target >= 0) {
                      layoutViewModel ? door.setHeight(Math.min(target, maxHeight)) : door.setHeight(target);
                  }
                }
              }}
              inputProps={{
                step: 0.25,
                min: 0,
              }}
              fullWidth
              margin="normal"
            />
            {!wallIsHorizontal && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={door.matchWallSlant}
                      onChange={(e) => {
                        door.setMatchWallSlant(e.target.checked)
                        if (parentWall) {
                          door.updateDoorHeight(parentWall);
                        }
                      }}
                      color="primary" 
                    />
                  }
                  label="Match Wall Slant"
                />
              </>
            )}
          </form>
        </AccordionDetails>
      </Accordion>
    </div>

  )
})

export default DoorProperties;