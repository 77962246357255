import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { saveAs } from 'file-saver';


export const ratioColors = [
  '#6699FF', // Medium Blue
  '#7FA9FF',
  '#99BBFF',
  '#B2CCFF',
  '#CCDEFF',
  '#E5F0FF',
  '#FFFFFF', // White
  '#FFE5E5',
  '#FFCCCC',
  '#FF9999',
  '#FF6666', // Medium Red
];

export const blueColors = ['#ffffff', '#E2EDF9', '#C5DBF3', '#A9CAEC', '#8CB8E6', '#6FA6DF', '#1565C0'];

export function getColorFromValue(colors: string[], min: number, max: number, value: number): string {
  if (value <= min) return colors[0];
  if (value >= max) return colors[colors.length - 1];

  const range = max - min;
  const step = range / (colors.length - 1);
  const index = Math.floor((value - min) / step);

  return colors[index];
}

export function exportToCSV(tableData: { values: CellData[][], rowLabels: RowColumnData[], columnHeaders: RowColumnData[] }, skipLast: boolean, fileName: string, formatter?: (value: number) => string) {
  const csvRows: string[] = [];

  // Add the header row
  const headerRow = ['Date/Time', 'Count'];
  csvRows.push(headerRow.join(','));

  // Loop through each row
  tableData.values.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {

      if (skipLast && rowIndex !== tableData.values.length - 1) {
        const label = cell.label ? cell.label : formatter ? formatter(cell.value) : cell.value;
        const row = [`${tableData.columnHeaders[colIndex]} ${tableData.rowLabels[rowIndex]}`, label];
        csvRows.push(row.join(','));
      }
    });
  });

  // Create the CSV content
  const csvContent = csvRows.join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Use file-saver to save the file
  saveAs(blob, fileName);
}

export function calcAverages(values: CellData[][], colors: string[]): { average: number, min: number, max: number, total: number } {

  let total = 0;
  let count = 0;
  let min = Number.MAX_SAFE_INTEGER;
  let max = Number.MIN_SAFE_INTEGER;

  values.forEach(row => {
    row.forEach(cell => {

        total += cell.data;
        count += 1;
        if (cell.data > max) max = cell.data;
        if (cell.data < min) min = cell.data;

    });
  });

  // Ensure min is set to 0 if no data was found
  if (min === Number.MAX_SAFE_INTEGER) {
    min = 0;
  }

  if (max === Number.MIN_SAFE_INTEGER) {
    max = 0;
  }

  const average = count > 0 ? total / count : 0;

  if (colors) {
    // Set the background color based on the min/max average
    values.forEach(row => {
      row.forEach(cell => {
        cell.backgroundColor = getColorFromValue(colors, min, max, cell.data); 
      });
    });
  }  

  return { average, min, max, total };
}
export interface CellData {
  label?: string;
  value: number;
  textColor?: string;
  backgroundColor?: string;
  tooltip?: string;
  data?: any;
  onDoubleClick?: (row: number, col: number, cell: CellData) => void;
}

export interface RowColumnData {
  label: string;
  textColor?: string;
  backgroundColor?: string;
  tooltip?: string;
}

export type CustomTableProps = {
  columnHeaders: RowColumnData[];
  rowLabels: RowColumnData[];
  values: CellData[][];
  defaultTextColor?: string;
  defaultBackgroundColor?: string;
  defaultOnDoubleClick?: (rowIndex: number, colIndex: number, cell: CellData) => void;
  formatter?: (value: number) => string;
};

const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: "collapse",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  textAlign: "center",
  padding: "4px 8px", // Reduce padding
}));

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  textAlign: "center",
  fontWeight: "bold",
  padding: "4px 8px", // Reduce padding
}));

const RowLabelTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  fontWeight: "bold",
  textAlign: "right", // Right justify the row labels
  padding: "4px 8px", // Reduce padding
}));

const CustomTable: React.FC<CustomTableProps> = ({
  columnHeaders,
  rowLabels,
  values,
  defaultTextColor = "white",
  defaultBackgroundColor = "black",
  defaultOnDoubleClick,
  formatter
}) => {
  const handleCellDoubleClick = (
    rowIndex: number,
    colIndex: number,
    cell: CellData
  ): void => {
    (cell.onDoubleClick || defaultOnDoubleClick)?.(rowIndex, colIndex, cell);
  };

  return (
    <TableContainer component={Paper} style={{ padding: "8px"}}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <HeaderTableCell></HeaderTableCell>
            {columnHeaders.map((header, colIndex) => {
              const headerContent = (
                <HeaderTableCell
                  key={colIndex}
                  style={{
                    color: header.textColor,
                    backgroundColor: header.backgroundColor,
                  }}
                >
                  {header.label}
                </HeaderTableCell>
              );

              return header.tooltip ? (
                <Tooltip
                  key={colIndex}
                  title={<span dangerouslySetInnerHTML={{ __html: header.tooltip }} />}
                  arrow
                >
                  {headerContent}
                </Tooltip>
              ) : (
                headerContent
              );
            })}

          </TableRow>
        </TableHead>
        <TableBody>
          {rowLabels.map((rowLabel, rowIndex) => (
            <TableRow key={rowIndex}>
              <RowLabelTableCell>{rowLabel.label}</RowLabelTableCell>
              {values[rowIndex].map((cell, colIndex) => {
                const cellContent = (
                  <StyledTableCell
                    key={colIndex}
                    style={{
                      color: cell.textColor ?? defaultTextColor,
                      backgroundColor: cell.backgroundColor ?? defaultBackgroundColor,
                    }}
                    onDoubleClick={() => handleCellDoubleClick(rowIndex, colIndex, cell)}
                  >
                    {cell.label ? cell.label : formatter ? formatter(cell.value) : cell.value.toString()}
                  </StyledTableCell>
                );

                return cell.tooltip ? (
                  <Tooltip
                    key={colIndex}
                    title={<span dangerouslySetInnerHTML={{ __html: cell.tooltip }} />}
                    arrow
                  >
                    {cellContent}
                  </Tooltip>
                ) : (
                  cellContent
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default CustomTable;
