import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Box, Checkbox, FormControlLabel, Slider, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";
import WindowEntity from "../models/layout/WindowEntity";
import { maximumHeightForWallFeature } from "../components-3d/WallsLayer";


const WindowProperties: FunctionComponent<{ window: WindowEntity }> = observer(({ window }) => {
    let layoutViewModel = useScanAreaLayoutViewModel();
    let parentWall = undefined;
    const [wallIsHorizontal, setWallIsHorizontal] = useState<boolean>(true);
    if (layoutViewModel) {
      parentWall = layoutViewModel.scanAreaLayout.walls.filter((wall) => wall.windows.includes(window))[0]
    }

    useEffect(() => {
      if (parentWall) {
        const horizontal = (parentWall.startCorner.position.y === parentWall.endCorner.position.y)
        setWallIsHorizontal(horizontal);
      }
    }, [parentWall?.startCorner.position.y, parentWall?.endCorner.position.y]);

    return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <DoorSlidingOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Window Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {window.name}

          <form>
            <TextField
              label="Name"
              value={window.name}
              onChange={(e) => {window.setName(e.target.value)}}
              fullWidth
              margin="normal"
            />
            
            <TextField
              label="Width"
              value={window.width}
              type="number"
              onChange={(e) => {
                let target = parseFloat(e.target.value);
                const maxWidth = 10

                //TODO: Dynamically Set?
                if (target > maxWidth) {
                  e.target.value = '' + maxWidth;
                }

                if (target >= 0 && target <= maxWidth) {
                    window.setWidth(target);
                    window.updateWindowHeight(parentWall)
                }
              }}
              inputProps={{
                step: 0.25,
              }}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Height"
              value={window.windowHeight}
              type="number"
              onChange={(e) => {
                let target = parseFloat(e.target.value);
                if (parentWall) {
                  const maxHeight = maximumHeightForWallFeature(parentWall, window);

                  if (target + window.verticalHeight > maxHeight) {
                    const highestPossible = maxHeight - window.verticalHeight
                    e.target.value = '' + highestPossible;
                    window.setWindowHeight(highestPossible);
                  } else {
                      window.setWindowHeight(target);
                  }
                }
              }}
              inputProps={{
                step: 0.25,
                min: 0
              }}
              fullWidth
              margin="normal"
            />

            <Typography variant="caption">
                Horizontal Position
            </Typography>
            <Slider
              size="small"
              value={window.distanceAlongWall}
              min={0}
              max={1}
              step={0.01}
              valueLabelDisplay="auto"
              onChange={(e, v) => {
                window.setDistanceAlongWall(v as number)
                window.updateWindowHeight(parentWall);
              }}
            />

            <TextField
              label="Vertical Position"
              value={window.verticalHeight}
              type="number"
              onChange={(e) => {
                let target = parseFloat(e.target.value);
                if (parentWall) {
                  const maxHeight = maximumHeightForWallFeature(parentWall, window);
                  const maxHeightNonSlanted = maximumHeightForWallFeature(parentWall, window, true);
                
                  if (target > maxHeightNonSlanted) {
                    e.target.value = '' + maxHeight;
                  } else {
                      window.setVerticalHeight(target);
                      if (window.verticalHeight + window.windowHeight > maxHeight) {
                        window.setWindowHeight(maxHeight - window.verticalHeight);
                      }
                  }
                }
              }}
              inputProps={{
                step: 0.25,
                min: 0,
              }}
              fullWidth
              margin="normal"
            />
            {!wallIsHorizontal && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={window.matchWallSlant}
                      onChange={(e) => {
                        window.setMatchWallSlant(e.target.checked)
                        if (parentWall) {
                          window.updateWindowHeight(parentWall);
                        }
                      }}
                      color="primary" 
                    />
                  }
                  label="Match Wall Slant"
                />
              </>
            )}
          </form>
        </AccordionDetails>
      </Accordion>
    </div>

  )
})

export default WindowProperties;