import React, { FC, FunctionComponent, MouseEvent, useEffect, useRef, useState } from 'react';
import { Box, Slider } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useSiteNavigator } from '../pages/SitePage';
import { SiteBionicsApplication, useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import { encodeColor } from '../util/Colors';
import { Color3 } from '@babylonjs/core';
import { observer } from 'mobx-react';
import { encode } from 'punycode';

interface BoundingBoxOverlayProps {
  showStationaryTrackedObjects: boolean;
  showShortLivedTrackedObjects: boolean;
}

const BoundingBoxOverlay: FC<BoundingBoxOverlayProps> = observer(({
     showStationaryTrackedObjects: showStationaryTrackledObjects, showShortLivedTrackedObjects: showShortLivedTrackledObjects }) => {
  
  const siteNavigator = useSiteNavigator();
  const siteBionicsApplication = useSiteBionicsApplication();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [maskUrl, setMaskUrl] = useState<string | undefined>(undefined);
  const [showMask, setShowMask] = useState(false); // Default mask visibility is off
  const [trackingState, setTrackingState] = useState<any | undefined>(undefined);

  useEffect(() => {
    siteBionicsApplication.service.fetchTrackingStateAsync(siteNavigator.site).then((trackingState) => {
      setTrackingState(trackingState);
    });
  }, []);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries.length === 0) return;
      const { width, height } = entries[0].contentRect;
      if (canvasRef.current) {
        const canvas = canvasRef.current;
  
        // Set the actual drawing size of the canvas
        canvas.width = width;
        canvas.height = height;
  
        // Update state to track size
        setSize({ width, height });
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (canvasRef.current) {
      observer.observe(canvasRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    siteNavigator.currentCamera?.getCameraMaskSasUrlAsync().then((sasUrl: any) => {
        setMaskUrl(sasUrl);
    });
  }, [siteNavigator.currentCamera]);

  useEffect(() => {
    // Handle keydown event for Ctrl+M to toggle mask visibility
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'm') {
        setShowMask(prevState => !prevState); // Toggle the mask visibility
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        
        // camera view outline
        ctx.clearRect(0, 0, size.width, size.height);
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        ctx.strokeStyle = encodeColor(siteNavigator.currentCamera?.color ?? Color3.Black());
        ctx.lineWidth = 2; 
        ctx.beginPath();
        ctx.rect(2, 2, size.width-4, size.height-4);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();

        if (showMask && trackingState && siteNavigator.currentCamera) {
          var trackedSyntheticObjects = trackingState?.trackedSyntheticObjects[siteNavigator.currentCamera.cameraId];
          trackedSyntheticObjects?.forEach((trackedSyntheticObject: any) => {
            var left = trackedSyntheticObject.bBox.left;
            var top = trackedSyntheticObject.bBox.top;
            var width = trackedSyntheticObject.bBox.width;
            var height = trackedSyntheticObject.bBox.height;
            var color = encodeColor(Color3.Gray());
            ctx.strokeStyle = color;
            ctx.fillStyle = color + "66";
            ctx.lineWidth = 1;
            ctx.strokeRect(left * size.width, top * size.height, width * size.width, height * size.height);
            ctx.fillRect(left * size.width, top * size.height, width * size.width, height * size.height);
          });
        }

        // bounding boxes
        siteNavigator.currentTracklets?.forEach(tracklet => {
          if (siteBionicsApplication 
              && siteNavigator.currentTime 
              && tracklet.camera?.cameraId === siteNavigator.currentCamera?.cameraId
              && (tracklet.isStationary === false || showStationaryTrackledObjects)
              && (tracklet.trackedObject?.isShortLived === false || showShortLivedTrackledObjects)) {
            let  closestDataPoint = tracklet.findClosestDataPoint(siteNavigator.currentTime.toDate());
            if (closestDataPoint) {
              var belongsToSelectedTrackedObject = (siteNavigator.currentTrackedObject && tracklet.trackedObject === siteNavigator.currentTrackedObject);
              var isSelectedTracklet = (siteNavigator.currentTracklet && tracklet === siteNavigator.currentTracklet);

              var left = closestDataPoint.bbox[0];
              var top = closestDataPoint.bbox[1];
              var width = closestDataPoint.bbox[2];
              var height = closestDataPoint.bbox[3];
              var color = belongsToSelectedTrackedObject ? "#FFFFFF" : encodeColor(siteNavigator.currentCamera?.color ?? Color3.Black());
              if (tracklet.isStationary) {
                color = encodeColor(Color3.Teal());
              }
              if (closestDataPoint.isMaskedOut) {
                color = encodeColor(Color3.Green());
              }
              ctx.strokeStyle = color;
              ctx.fillStyle = color + "66";
              ctx.lineWidth = belongsToSelectedTrackedObject ? 2 : 1;
              ctx.strokeRect(left * size.width, top * size.height, width * size.width, height * size.height);
              ctx.fillRect(left * size.width, top * size.height, width * size.width, height * size.height);
              

               // Add trackletId label above the bounding box
              ctx.strokeStyle = "#FFFFFF"
              ctx.lineWidth = 1;
              ctx.font = '15px Arial';
              ctx.strokeText(`ID: ${tracklet.objectId}`, left * size.width, (top * size.height) - 10);
              if (tracklet.trackedObject?.startsAtEntrance)
                ctx.strokeText(`In: ${tracklet.trackedObject.startsAtEntrance}`, left * size.width, (top * size.height) + 15);
              if (tracklet.trackedObject?.endsAtEntrance)
                ctx.strokeText(`Out: ${tracklet.trackedObject.endsAtEntrance}`, left * size.width, (top * size.height) + 30);
              if (closestDataPoint.confidence)
                ctx.strokeText(`C: ${closestDataPoint.confidence.toFixed(2)}`, left * size.width, (top * size.height) + 45);
            }
          }
        });
      }
    }
  }, [showMask, siteNavigator.currentCamera, trackingState, siteNavigator.currentTracklets, siteNavigator.currentTracklet, siteNavigator.currentTrackedObject, siteNavigator.currentTime]);

  return (
    <Box component="div" sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}>
      <img 
        src={maskUrl} 
        alt=""
        style={{
          opacity: showMask ? 0.5 : 0,  // Toggle the mask visibility based on showMask state
          width: '100%', 
          height: '100%', 
          objectFit: 'contain', 
          top: 0, 
          left: 0, 
          objectPosition: 'center',
          pointerEvents: 'none'
        }} 
      />
      <canvas ref={canvasRef} style={{width: '100%', height: "100%", position: 'absolute', top: 0, left: 0, pointerEvents: 'none', zIndex: 10}} />
    </Box>
  );
});

export default BoundingBoxOverlay;
