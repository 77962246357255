import { FunctionComponent, Key, useState } from "react";
import { observer } from "mobx-react"
import { Alert, Box, Button, Divider, List, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import WallEntity from "../models/layout/WallEntity";
import { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";
import CornerEntity from "../models/layout/CornerEntity";
import DoorEntity, { findNextAvailableDoorName } from "../models/layout/DoorEntity";
import WindowEntity, { findNextAvailableWindowName } from "../models/layout/WindowEntity";
import { handleAddWall } from "./FloorPlanAccordion";

const WallProperties: FunctionComponent<{ wall: WallEntity }> = observer(({ wall }) => {
  
  let layoutViewModel = useScanAreaLayoutViewModel();

  const euclideanDistance = (corner1: CornerEntity, corner2: CornerEntity) => {
    const dx = corner2.position.x - corner1.position.x;
    const dy = corner2.position.y - corner1.position.y;
    const dz = corner2.position.z - corner1.position.z || 0;
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  }

  const handleAddDoor = (wall: WallEntity) => {
    let newDoorName = layoutViewModel ? findNextAvailableDoorName(layoutViewModel) : "New Door";
    let width = Math.min(0.5, euclideanDistance(wall.startCorner, wall.endCorner) / 2);
    let newDoor = new DoorEntity({
      name: newDoorName,
      distanceAlongWall: 0.5,
      height: 2,
      width: width
    });

    wall.addDoorToWall(newDoor);
    layoutViewModel?.selectEntity(newDoor);
  }

  const handleAddWindow = (wall: WallEntity) => {
    let newWindowName = layoutViewModel ? findNextAvailableWindowName(layoutViewModel) : "New Window";
    let width = Math.min(0.5, euclideanDistance(wall.startCorner, wall.endCorner) / 2);

    let windowVerticalHeight = 1;
    let windowHeight = 1.5;

    if (layoutViewModel) {
      windowHeight + windowHeight < layoutViewModel.scanAreaLayout.defaultWallHeight ? windowVerticalHeight = windowVerticalHeight : windowVerticalHeight = 0;
    }

    let newWindow = new WindowEntity({
      name: newWindowName,
      distanceAlongWall: 0.5,
      windowHeight: windowHeight,
      verticalHeight: windowVerticalHeight,
      width: width
    });

    wall.addWindowToWall(newWindow);
    layoutViewModel?.selectEntity(newWindow);
  }

  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <DoorSlidingOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Wall Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {wall.name}

          <form>
            <TextField
              label="Name"
              value={wall.name}
              onChange={(e) => {wall.setName(e.target.value)}}
              fullWidth
              margin="normal"
            />
            
            <Divider style={{ height: "3px", margin: "16px 0" }} />
            
            <Box component="div" display="flex" flexDirection="row" justifyContent="center" key={wall.startCorner.id + "-addDoor"}
            >
              <Button id="addDoorToWall" onClick={() => handleAddDoor(wall)}>
                Add Door
              </Button>
            </Box>
            
            <Box component="div" display="flex" flexDirection="row" justifyContent="center" key={wall.startCorner.id + "-addWindow"}>
              <Button id="addWindowToWall" onClick={() => handleAddWindow(wall)}>
                Add Window
              </Button>
            </Box>

            <Box
              component="div"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              key={wall.startCorner.id + "-addWall"}
              onMouseEnter={() => { layoutViewModel?.setShowSpecificCornerId(wall.startCorner.id); }}
              onMouseLeave={() => { layoutViewModel?.setShowSpecificCornerId(""); }}
            >
              <Button
                id="addWallFromStart"
                onClick={() => {
                  if (layoutViewModel) {
                    handleAddWall(layoutViewModel, wall, wall.startCorner)}
                  }
                }
              >
                Add Wall From Start Corner
              </Button>
            </Box>

            <Box
              component="div"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              key={wall.endCorner.id + "-addWall"}
              onMouseEnter={() => { layoutViewModel?.setShowSpecificCornerId(wall.endCorner.id); }}
              onMouseLeave={() => { layoutViewModel?.setShowSpecificCornerId(""); }}
            >
              <Button
                id="addWallFromEnd"
                onClick={() => {
                  if (layoutViewModel) {
                    handleAddWall(layoutViewModel, wall, wall.endCorner)}
                  }
                }              >
                Add Wall From End Corner
              </Button>
            </Box>

            <Divider style={{ height: "3px", margin: "16px 0" }} />
            <Typography variant="body1">
                Start Corner
            </Typography>
            <TextField
              label="Position X"
              type="number"
              value={wall.startCorner.position.x}
              onChange={(e) => {
                wall.startCorner.position.setX(parseFloat(e.target.value));
                wall.startCorner.updateAllLinkedCornerPositions();
                wall.doors.forEach(door => door.updateDoorHeight(wall));
                wall.windows.forEach(window => window.updateWindowHeight(wall));
              }}
              onMouseEnter={() => { layoutViewModel?.setShowSpecificCornerId(wall.startCorner.id); }}
              onMouseLeave={() => { layoutViewModel?.setShowSpecificCornerId(""); }}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Position Y"
              type="number"
              value={wall.startCorner.position.y}
              onChange={(e) => {
                wall.startCorner.position.setY(parseFloat(e.target.value));
                wall.startCorner.updateAllLinkedCornerPositions();
                wall.doors.forEach(door => door.updateDoorHeight(wall));
                wall.windows.forEach(window => window.updateWindowHeight(wall));
              }}
              onMouseEnter={() => { layoutViewModel?.setShowSpecificCornerId(wall.startCorner.id); }}
              onMouseLeave={() => { layoutViewModel?.setShowSpecificCornerId(""); }}
              fullWidth
              margin="normal"
              inputProps={{
                step: 0.25,
                min: 0,
              }}
            />

            <TextField
              label="Position Z"
              type="number"
              value={wall.startCorner.position.z}
              onChange={(e) => {
                wall.startCorner.position.setZ(parseFloat(e.target.value));
                wall.startCorner.updateAllLinkedCornerPositions();
                wall.doors.forEach(door => door.updateDoorHeight(wall));
                wall.windows.forEach(window => window.updateWindowHeight(wall));
              }}
              onMouseEnter={() => { layoutViewModel?.setShowSpecificCornerId(wall.startCorner.id); }}
              onMouseLeave={() => { layoutViewModel?.setShowSpecificCornerId(""); }}
              fullWidth
              margin="normal"
            />

            <Divider style={{ margin: "16px 0" }} />
            <Typography variant="body1">
                End Corner
            </Typography>
            <TextField
              label="Position X"
              value={wall.endCorner.position.x}
              type="number"
              onChange={(e) => {
                wall.endCorner.position.setX(parseFloat(e.target.value));
                wall.endCorner.updateAllLinkedCornerPositions();
                wall.doors.forEach(door => door.updateDoorHeight(wall));
                wall.windows.forEach(window => window.updateWindowHeight(wall));
              }}              
              onMouseEnter={() => { layoutViewModel?.setShowSpecificCornerId(wall.endCorner.id); }}
              onMouseLeave={() => { layoutViewModel?.setShowSpecificCornerId(""); }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Y"
              type="number"
              value={wall.endCorner.position.y}
              onChange={(e) => {
                wall.endCorner.position.setY(parseFloat(e.target.value));
                wall.endCorner.updateAllLinkedCornerPositions();
                wall.doors.forEach(door => door.updateDoorHeight(wall));
                wall.windows.forEach(window => window.updateWindowHeight(wall));
              }}
              onMouseEnter={() => { layoutViewModel?.setShowSpecificCornerId(wall.endCorner.id); }}
              onMouseLeave={() => { layoutViewModel?.setShowSpecificCornerId(""); }}
              fullWidth
              margin="normal"
              inputProps={{
                step: 0.25,
                min: 0,
              }}
            />
            <TextField
              label="Position Z"
              type="number"
              value={wall.endCorner.position.z}
              onChange={(e) => {
                wall.endCorner.position.setZ(parseFloat(e.target.value));
                wall.endCorner.updateAllLinkedCornerPositions();
                wall.doors.forEach(door => door.updateDoorHeight(wall));
                wall.windows.forEach(window => window.updateWindowHeight(wall));
              }}
              onMouseEnter={() => { layoutViewModel?.setShowSpecificCornerId(wall.endCorner.id); }}
              onMouseLeave={() => { layoutViewModel?.setShowSpecificCornerId(""); }}
              fullWidth
              margin="normal"
            />

          </form>
        </AccordionDetails>
      </Accordion>
    </div>

  )
})

export default WallProperties;