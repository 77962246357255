import { getModelMetadata, fromSnapshot, Model } from "mobx-keystone";
import ScanArea from "../models/ScanArea";
import EntranceEntity from "../models/layout/EntranceEntity";
import RegionEntity from "../models/layout/RegionEntity";
import PoseModel from "../models/layout/PoseModel";
import Vector3Model from "../models/layout/Vector3Model";
import Color3Model from "../models/layout/Color3Model";
import QuaternionModel from "../models/layout/QuaternionModel";
import { GUID } from "@babylonjs/core";

export function decodeBase64ToBooleanArray(base64: string): boolean[] {
  if (!base64) return [];
  const binaryStr = atob(base64);
  const booleanArray: boolean[] = [];
  
  for (let i = 0; i < binaryStr.length; i += 1) {
    const byteValue = binaryStr.charCodeAt(i);
    booleanArray.push(byteValue !== 0);
  }

  return booleanArray;
}


export function decodeBase64ToShortArray(base64: string): number[] {
  if (!base64) return [];
  const binaryStr = atob(base64);
  const shortArray: number[] = [];
  
  for (let i = 0; i < binaryStr.length; i += 2) {
    const highByte = binaryStr.charCodeAt(i + 1);
    const lowByte = binaryStr.charCodeAt(i);
    const shortValue = (highByte << 8) | lowByte;
    shortArray.push(shortValue);
  }

  return shortArray;
}

export function decodeBase64ToUShortArray(base64: string): number[] {
  if (!base64) return [];
  const binaryStr = atob(base64);
  const ushortArray: number[] = [];
  
  for (let i = 0; i < binaryStr.length; i += 2) {
    const highByte = binaryStr.charCodeAt(i + 1);
    const lowByte = binaryStr.charCodeAt(i);
    const ushortValue = (highByte << 8) | lowByte;

    // Ensure we interpret as unsigned by applying & 0xFFFF
    ushortArray.push(ushortValue & 0xFFFF);
  }

  return ushortArray;
}

export function decodeBase64ToFloatArray(base64: string): Float32Array {
  if (!base64) return new Float32Array();

  // Decode the Base64 string to a binary string
  const binaryString = atob(base64);

  // Create a byte array from the binary string
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
  }

  // Convert the byte array to a Float32Array
  return new Float32Array(byteArray.buffer);
}

function pascalToCamelCase(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function convertPascalToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => convertPascalToCamelCase(item)) as unknown as any;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = pascalToCamelCase(key);
      acc[camelCaseKey] = convertPascalToCamelCase(obj[key]);
      return acc;
    }, {} as Record<string, any>) as any;
  }
  return obj;
}

function camelToPascalCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertCamelToPascalCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => convertCamelToPascalCase(item)) as unknown as any;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const pascalCaseKey = camelToPascalCase(key);
      acc[pascalCaseKey] = convertCamelToPascalCase(obj[key]);
      return acc;
    }, {} as Record<string, any>) as any;
  }
  return obj;
}

const modelPropertyTypes : any = {
  "SiteBionics/ScanAreaLayout": {
    entrances: "SiteBionics/EntranceEntity",
    triggers: "SiteBionics/TriggerEntity",
    regions: "SiteBionics/RegionEntity",
    areaModels: "SiteBionics/AreaModelEntity",
    cameras: "SiteBionics/CameraEntity",
    walls: "SiteBionics/WallEntity",
    defaultWallHeight: "number",
  },
  "SiteBionics/AreaModelEntity": {
    pose: "SiteBionics/PoseModel",
    clipHeight: "number",
  },
  "SiteBionics/RegionEntity": {
    position: "SiteBionics/Vector3Model",
    color: "SiteBionics/Color3Model",
    polygonPoints: "SiteBionics/Vector3Model",
  },
  "SiteBionics/EntranceEntity": {
    pose: "SiteBionics/PoseModel",
  },
  "SiteBionics/TriggerEntity": {
    pose: "SiteBionics/PoseModel",
  },
  "SiteBionics/CameraEntity": {
    pose: "SiteBionics/PoseModel",
    alignmentPoints: "SiteBionics/Vector2Model",
  },
  "SiteBionics/PoseModel": {
    position: "SiteBionics/Vector3Model",
    orientation: "SiteBionics/QuaternionModel",
  },
  "SiteBionics/CornerEntity": {
    position: "SiteBionics/Vector3Model",
    isStart: "boolean"
  },
  "SiteBionics/DoorEntity": {
    distanceAlongWall: "number",
    doorWidth: "number",
    doorName: "string",
    doorHeight: "number",
    matchWallSlant: "boolean",

  },
  "SiteBionics/WindowEntity": {
    distanceAlongWall: "number",
    windowWidth: "number",
    windowName: "string",
    verticalHeight: "number",
    windowHeight: "number",
    matchWallSlant: "boolean",
  },
  "SiteBionics/WallEntity": {
    startCorner: "SiteBionics/CornerEntity",
    endCorner: "SiteBionics/CornerEntity",
    wallName: "string",
    doors: "SiteBionics/DoorEntity",
    windows: "SiteBionics/WindowEntity",
  },
  "SiteBionics/Vector3Model": {
    // no nested models here
  },
  "SiteBionics/Vector2Model": {
    // no nested models here
  },
  "SiteBionics/QuaternionModel": {
    // no nested models here
  },

};

// Utility function to add $modelType recursively
export function addModelTypeAndId(json: any, modelType: string): any {
  if (Array.isArray(json)) {
    return json.map(item => addModelTypeAndId(item, modelType));
  } else if (json !== null && typeof json === 'object') {
    const properties = modelPropertyTypes[modelType] || {};

    const transformed = {
      $modelType: modelType,
      id: GUID.RandomId(),
      ...Object.keys(json).reduce((acc, key) => {
        const value = json[key];
        const propModelType = properties[key];

        if (Array.isArray(value) && propModelType) {
          // Handle arrays of models
          acc[key] = addModelTypeAndId(value, propModelType);
        } else if (propModelType) {
          // Handle nested models
          acc[key] = addModelTypeAndId(value, propModelType);
        } else {
          // Handle other properties
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>)
    };

    return transformed;
  }
  return json;
}

export function removeModelType(json: any): any {
  if (Array.isArray(json)) {
    return json.map(item => removeModelType(item));
  } else if (json !== null && typeof json === 'object') {
    return Object.keys(json).reduce((acc, key) => {
      if (key !== "$modelType") {
        acc[key] = removeModelType(json[key]);
      }
      return acc;
    }, {} as Record<string, any>);
  }
  return json;
}
