import SiteIntegration from "./SiteIntegration";
import { Dayjs } from "dayjs";
import ScanAreaLayout from "./layout/ScanAreaLayout";
import TrackedObject, { TimeSpanDictionary } from "./TrackedObject";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ProvisionedHub from "./ProvisionedHub";
import {InteractionRequiredAuthError, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import Tracklet from "./Tracklet";
import { cameraColors, integerToColor } from "../util/Colors";
import TrackletDataPoint from "./TrackletDataPoint";
import Camera from "./Camera";
import Site from "./Site";
import Hub from "./Hub";
import DataContext from "./DataContext";
import Account from "./Account";
import ScanArea from "./ScanArea";
import Scan from "./Scan";
import UserAccountRole from "./UserAccountRole";
import UserSiteRole from "./UserSiteRole";
import UserInfo from "./UserInfo";
import Address from "./Address";
import { AccountInvite, SiteInvite} from "../models/Invite";
import { RoleMap } from "./RoleMap";
import { Color3 } from "@babylonjs/core";
import MRUData from "./MRUData";
import TrackedObjectEvent from "./TrackedObjectEvent";
import HeatmapEntry, { HeatmapCellEntry } from "./HeatmapEntry";
import {HubStatusResponse} from "./HubStatusResponse";
import { FragmentUploadPriority } from "./FragmentUploadPriority";
import { fromSnapshot, getSnapshot } from "mobx-keystone";
import { addModelTypeAndId, convertCamelToPascalCase, convertPascalToCamelCase, removeModelType } from "../util/JsonUtil";
import "./layout/EntranceEntity";
import "./layout/RegionEntity";
import "./layout/ScanAreaLayout";
import { parseTimeSpan } from "../util/Time";
import TaggedObjectCounts from "./TaggedObjectCounts";
import Shift, { ShiftUpdate } from "./Shift";
import { SiteEvent, SiteEventUpdate } from "./SiteEvent";
import { DwellTimeBucket, DwellTimeSummary, DwellTimeDetail, DwellTimeTotal } from "./DwellTime";
import { ModelType } from "./ModelType";
import timezone from 'dayjs/plugin/timezone';
import TrackedObjectDataPoint from "./TrackedObjectDataPoint";

dayjs.extend(utc);
dayjs.extend(timezone);

export class HubRecordingRequest {
  enableRecording: boolean = false;
  enableTracking: boolean = false;
  constructor(enableRecording: boolean, enableTracking: boolean) {
    this.enableRecording = enableRecording;
    this.enableTracking = enableTracking;
  }
}

export class SiteBionicsService {

  // To change this to localhost, create a file .env.development containing the appropriate URL. e.g. :
  // REACT_APP_API_URL=http://localhost:7115
  // Note: you might need to npm install dotenv
  static serverRoot = process.env.REACT_APP_API_URL ?? "https://api.sitebionics.com";
  static authServerRoot = process.env.REACT_APP_TOKEN_API_URL ?? "https://auth.sitebionics.com";

  // Core authentication type
  coreAuthType: string = "";

  // msal token
  msalInstance : PublicClientApplication | undefined = undefined;
  msalAccessToken : string = "";
  msalAccessTokenRefreshTime = Date.now();

  // Access token
  accessToken: string = "";
  accessTokenRefreshTime = Date.now();

  dataContext: DataContext = new DataContext(this);

  //-------------
  // Utilities

  async getMsalToken() {
    if (this.msalAccessToken !== "" && this.msalAccessTokenRefreshTime > Date.now()) {
        return this.msalAccessToken;
    }

    const account = this.msalInstance!.getAllAccounts()[0];
    var request : SilentRequest = {scopes: ["api://0136c655-9040-40b0-951a-72268d5396a2/Sites.Read"], account}
    
    try {
        const response = await this.msalInstance!.acquireTokenSilent(request);
        this.msalAccessToken = response.accessToken;
        this.msalAccessTokenRefreshTime = Date.now() + 60 * 1000;
        console.log("token refreshed silently");
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            // Fallback to interactive method if needed
            const response = await this.msalInstance!.acquireTokenPopup(request);
            this.msalAccessToken = response.accessToken;
            this.msalAccessTokenRefreshTime = Date.now() + 60 * 1000;
            console.log("token refreshed with popup");
            return response.accessToken;
        }
        throw error;
    }
  }

  async getAccessToken() {
    const cachedToken = this.getCachedToken();
    if (cachedToken !== null) {
      return cachedToken;
    }

    if (this.accessToken !== "" && this.accessTokenRefreshTime > Date.now()) {
      return this.accessToken;
    }
    
    const url = `${SiteBionicsService.authServerRoot}/api/tokens/frommsal`;
    const response = await this.fetchWithToken(url, "Bearer");
    this.accessToken = await response.text();
    this.accessTokenRefreshTime = Date.now() + 60 * 1000;
    return this.accessToken;
  }    

  // Playwright tests cache the token in localStorage
  getCachedToken() {
    return localStorage.getItem("sbjwt");
  }

  async _getToken(tokenType: string): Promise<string[]> {
    // Playwright tests cache the token in localStorage. If this exists, use it.
    const cachedToken = this.getCachedToken();
    if (cachedToken !== null) {
      this._defaultTokenType = "sbjwt";
      return ["sbjwt", cachedToken];
    }

    let token = "";
    if (tokenType === "Bearer") {
      token = await this.getMsalToken();
    } else {
      tokenType = "sbjwt";
      token = await this.getAccessToken();
    }

    return [tokenType, token];
  }

  _defaultTokenType = "Bearer";

  async fetchWithToken (url: string, tokenType: string = this._defaultTokenType)  {
    let token = "";

    [tokenType, token] = await this._getToken(tokenType);

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `${tokenType} ${token}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache', // HTTP 1.1.
            'Pragma': 'no-cache',        // HTTP 1.0.
            'Expires': '0',   
        }
    });

    return response;
  };
  
  async postWithToken (url: string, tokenType: string = this._defaultTokenType)  {
    const [_tokenType, _token] = await this._getToken(tokenType);
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': `${_tokenType} ${_token}`
        }
    });
    return response;
  };

  async postDataWithToken (url: string, data: any, tokenType: string = this._defaultTokenType)  {
    const [_tokenType, _token] = await this._getToken(tokenType);    
    const json = JSON.stringify(data);
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': `${_tokenType} ${_token}`,
            'Content-Type': 'application/json'
        }, 
        body: json
    });
    return response;
  }

  async putDataWithToken(url: string, data: any, tokenType: string = this._defaultTokenType) {
    const [_tokenType, _token] = await this._getToken(tokenType);    
    const json = JSON.stringify(data);
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Authorization': `${_tokenType} ${_token}`,
            'Content-Type': 'application/json'
        }, 
        body: json
    });
    return response;    
  }

  async patchDataWithToken (url: string, data: any, tokenType: string = this._defaultTokenType)  {
    const [_tokenType, _token] = await this._getToken(tokenType);    
    const json = JSON.stringify(data);
    const response = await fetch(url, {
        method: "PATCH",
        headers: {
            'Authorization': `${_tokenType} ${_token}`,
            'Content-Type': 'application/json'
        }, 
        body: json
    });
    return response;
  };


  async deleteWithToken (url: string, tokenType: string = this._defaultTokenType)  {
    const [_tokenType, _token] = await this._getToken(tokenType);    
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            'Authorization': `${_tokenType} ${_token}`
        }
    });
    return response;
  };

  //-----------
  // Accounts

  async fetchAccountListAsync() {
    const url = `${SiteBionicsService.serverRoot}/api/accounts`;
    const response = await this.fetchWithToken(url);
    if (response.status === 404) return [];
    const responseObj : object[] = await response.json();
    const accountList = responseObj.map((a : any) => new Account(this.dataContext, a.id, a.accountName, a.accountRoles, a.baseSiteRoles, a.accountCapabilities, a.baseSiteCapabilities, a.billingAddress));
    return accountList;
  }

  async fetchAccountAsync(accountId: string) : Promise<Account | null> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}`;
    const response = await this.fetchWithToken(url);
    if (response.status === 404) {
      return null;
    }
    const responseObj : any = await response.json();
    const account = new Account(this.dataContext, responseObj.id, responseObj.accountName, responseObj.accountRoles, responseObj.baseSiteRoles, responseObj.accountCapabilities, responseObj.baseSiteCapabilities, responseObj.billingAddress);
    return account;
  }

  async createAccountAsync(accountName: string, billingAddress: Address) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts`
    const _response = await this.postDataWithToken(url, {accountName: accountName, billingAddress: billingAddress});
    const responseObj : any = await _response.json();
    const newAccount = new Account(this.dataContext, responseObj.id, responseObj.accountName, responseObj.accountRoles, responseObj.baseSiteRoles, responseObj.accountCapabilities, responseObj.baseSiteCapabilities, responseObj.billingAddress);

    return newAccount;
  }

  async updateAccount(accountId: string, accountName: string, billingAddress: Address) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}`;
    const _response = await this.putDataWithToken(url, {accountName: accountName, billingAddress: billingAddress});
    return;
  }

  async deleteAccountAsync(accountId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}`
    const _response = await this.deleteWithToken(url);
    return;
  }  

  //-----------
  // Users

  async fetchUserInfo(userId: string): Promise<UserInfo | null>  {
    //const accessToken = await this.getAccessToken();

    const url = `${SiteBionicsService.serverRoot}/api/users/${userId}`
    const response = await this.fetchWithToken(url);
    
    if (response.status === 404)
      return null;

    const responseObj : any = await response.json();

    return new UserInfo(responseObj.id, responseObj.email, responseObj.firstName, responseObj.lastName, responseObj.tosVersion, responseObj.tosDate);    
  }

  async upsertUserInfo(userInfo: UserInfo) : Promise<boolean> {
    //const accessToken = await this.getAccessToken();

    const url = `${SiteBionicsService.serverRoot}/api/users/${userInfo.id}`;

    const response = await this.putDataWithToken(url, userInfo);

    if (response.ok) return true;

    return false;
  }

  // Sys admins only
  async fetchAllUsers(): Promise<UserInfo[]> {
    const url = `${SiteBionicsService.serverRoot}/api/users`;
    const response = await this.fetchWithToken(url);
    const responseObj : object[] = await response.json();
    const userList = responseObj.map((u : any) => new UserInfo(u.id, u.email, u.firstName, u.lastName, u.tosVersion, u.tosDate));
    return userList;
  }

  // Sys admins only
  async deleteUser(userId: string): Promise<void> {
    const url = `${SiteBionicsService.serverRoot}/api/users/${userId}`;
    const _response = await this.deleteWithToken(url);
    return;
  }

  //-------------
  // Invitations

  async fetchAccountInvitation(accountId: string, inviteId: string) : Promise<AccountInvite | null> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/invites/${inviteId}`;    
    const response = await this.fetchWithToken(url);
    if (response.status === 404) return null;
    const responseObj : object[] = await response.json();
    return new AccountInvite(responseObj);
  }

  async fetchAccountInvites(accountId: string) : Promise<AccountInvite[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/invites`;    
    const response = await this.fetchWithToken(url);
    if (response.status === 404) return [];
    const responseObj : object[] = await response.json();    
    const accountInviteList = responseObj.map((a : any) => { return new AccountInvite(a)});
    return accountInviteList;
  }

  async createAccountInvitation(accountId: string, accountRoles: string[], baseSiteRoles: string[], receiverEmail: string, requiresMatch: boolean) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/invites`;
    let data = { accountRoles: accountRoles, baseSiteRoles: baseSiteRoles, receiverEmail: receiverEmail, requiresMatch: requiresMatch};
    await this.postDataWithToken(url, data);
    return;
  }

  async updateAccountInvitation(accountId: string, inviteId: string, accountRoles: string[], baseSiteRoles: string[], requiresMatch: boolean, resendEmail: boolean) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/invites/${inviteId}`;
    let data = { accountRoles: accountRoles, baseSiteRoles: baseSiteRoles, requiresMatch: requiresMatch, resendEmail: resendEmail};
    await this.putDataWithToken(url, data);
    return;
  }

  async acceptAccountInvitation(accountId: string, inviteId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/invites/${inviteId}/accept`;    
    await this.postWithToken(url);
    return;
  }

  async deleteAccountInvitation(accountId: string, inviteId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/invites/${inviteId}`;    
    await this.deleteWithToken(url);
    return;
  }

  async fetchSiteInvitation(accountId: string, siteId: string, inviteId: string) : Promise<SiteInvite | null> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/invites/${inviteId}`;    
    const response = await this.fetchWithToken(url);
    if (response.status === 404) return null;
    const responseObj : object[] = await response.json();
    return new SiteInvite(responseObj);
  }

  async fetchSiteInvites(accountId: string, siteId: string) : Promise<SiteInvite[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/invites`;    
    const response = await this.fetchWithToken(url);
    if (response.status === 404) return [];
    const responseObj : object[] = await response.json();
    const siteInviteList = responseObj.map((a : any) => new SiteInvite(a));
    return siteInviteList;
  }  

  async createSiteInvitation(accountId: string, siteId: string, siteRoles: string[], receiverEmail: string, requiresMatch: boolean) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/invites`;
    let data = { siteRoles: siteRoles, receiverEmail: receiverEmail, requiresMatch: requiresMatch};
    await this.postDataWithToken(url, data);
    return;
  }

  async updateSiteInvitation(accountId: string, siteId: string, inviteId: string, siteRoles: string[], requiresMatch: boolean, resendEmail: boolean) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/invites/${inviteId}`;
    let data = { siteRoles: siteRoles, requiresMatch: requiresMatch, resendEmail: resendEmail };
    await this.putDataWithToken(url, data);
    return;
  }

  async acceptSiteInvitation(accountId: string, siteId: string, inviteId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/invites/${inviteId}/accept`;    
    await this.postWithToken(url);
    return;
  }

  async deleteSiteInvitation(accountId: string, siteId: string, inviteId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/invites/${inviteId}`;    
    await this.deleteWithToken(url);
    return;
  }

  //------------
  // Role maps
  async fetchRoleMap(accountId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/rolemaps`
    const response = await this.fetchWithToken(url);    
    const responseObj : any = await response.json();
    if (responseObj.status === 404) return [];
    return new RoleMap(responseObj.accountRoles, responseObj.siteRoles);  
  }

  // ----------
  // Account Roles
  async fetchUserAccountRoles(accountId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/roles`
    const response = await this.fetchWithToken(url);
    if (response.status === 404) {
      return [];
    }
    const responseObj : object[] = await response.json();
    const userAccountRoleList = responseObj.map((a : any) => new UserAccountRole(accountId, a.userId, a.accountRoles, a.baseSiteRoles));
    return userAccountRoleList;
  }

  async upsertUserAccountRoles(accountId: string, accountRole: UserAccountRole) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/roles`
    const _response = await this.putDataWithToken(url, [accountRole]);    
    return;
  }

  async deleteUserAccountRoles(accountId: string, userIds: string[]) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/roles?userIds=${userIds.join(",")}`;
    const _response = await this.deleteWithToken(url);
    return;
  }

  //----------
  // Site Roles

  async fetchUserSiteRoles(accountId: string, siteId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/roles`
    const response = await this.fetchWithToken(url);
    if (response.status === 404) {
      return [];
    }
    const responseObj : object[] = await response.json();
    const userSiteRoleList = responseObj.map((a : any) => new UserSiteRole(accountId, siteId, a.userId, a.siteRoles));
    return userSiteRoleList;
  }

  async upsertUserSiteRoles(accountId: string, siteId: string, siteRole: UserSiteRole) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/roles`
    const _response = await this.putDataWithToken(url, [siteRole]);    
    return;
  }

  async deleteUserSiteRoles(accountId: string, siteId: string, userId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/roles?userIds=${userId}`
    const _response = await this.deleteWithToken(url); 
    return;
  }

  //----------------
  // UserData

  async fetchUserData(key: string) {
    const url = `${SiteBionicsService.serverRoot}/api/userdata/${key}`;
    const response = await this.fetchWithToken(url);
    if (response.status === 404) {
      return null;
    }
    const responseObject: object = await response.json();

    return responseObject;
  }

  async upsertUserData(key: string, data: object) {
    const url = `${SiteBionicsService.serverRoot}/api/userdata/${key}`;
    const _response = await this.putDataWithToken(url, data);
    return;
  }

  async deleteUserData(key: string) {
    const url = `${SiteBionicsService.serverRoot}/api/userdata/${key}`;
    const _response = await this.deleteWithToken(url);
    return;
  }

  async fetchMRUUserData() {
    let data : any = await this.fetchUserData("mru");
    
    if (data === null) {
      return new MRUData(this.dataContext, [], {});
    }

    return new MRUData(this.dataContext, data.accounts, data.sites);
  }

  async upsertMRUUserData(mruData: MRUData) {
    const data = { sites: mruData.sites, accounts: mruData.accounts}
    await this.upsertUserData("mru", data);
  }

  async deleteMRUUserData() {
    await this.deleteUserData("mru");
  }
  
  //----------
  // Provisioned Hubs

  async fetchProvisionedHubListAsync(): Promise<ProvisionedHub[] | null> {
    const url = `${SiteBionicsService.serverRoot}/api/management/provisionedhubs`
    const response = await this.fetchWithToken(url);
    if (response.status !== 200) return null;
    const responseObj : object[] = await response.json();
    const provisionedHubList = responseObj.map((h : any) => new ProvisionedHub(h.id, 
          h.primaryThumbprint, h.secondaryThumbprint, h.provisionedByUserId, 
          h.pairingCode, h.pairedSiteId, h.pairedAccountId, h.notes));
    return provisionedHubList;
  }

  async fetchProvisionedHubAsync(hubId: string): Promise<ProvisionedHub | null> {
    const url = `${SiteBionicsService.serverRoot}/api/management/provisionedhubs/${hubId}`;
    const response = await this.fetchWithToken(url);
    
    if (response.status !== 200) return null;
    
    const h : any = await response.json();
    
    const provisionedHub = new ProvisionedHub(h.id, 
          h.primaryThumbprint, h.secondaryThumbprint, h.provisionedByUserId, 
          h.pairingCode, h.pairedSiteId, h.pairedAccountId, h.notes);

    return provisionedHub;
  }

  async updateProvisionedHubNotes(hubId: string, notes: string): Promise<number> {
    const url = `${SiteBionicsService.serverRoot}/api/management/provisionedhubs/${hubId}/notes`;
    const response = await this.patchDataWithToken(url, notes);
    return response.status;
  }

  async resetProvisionedHub(hubId: string): Promise<string | null> {
    const url = `${SiteBionicsService.serverRoot}/api/management/provisionedhubs/${hubId}/reset`;
    const response = await this.postWithToken(url);
    if (response.status !== 200) return null;
    const responseObj : any = await response.json();
    return responseObj.pairingCode;
  }

  async deleteProvisionedHub(hubId: string): Promise<number> {
    const url = `${SiteBionicsService.serverRoot}/api/management/provisionedhubs/${hubId}`;
    const response = await this.deleteWithToken(url);
    return response.status;
  }

  //------------------
  // Site Hubs

  async fetchHubAsync(site: Site, hubId: string): Promise<Hub | null> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/hubs/${hubId}`;
    const response = await this.fetchWithToken(url);
    if (response.status !== 200) return null;
    const responseObj = await response.json();
    return new Hub(this.dataContext, site, responseObj.id, responseObj.name);
  }
  
  async fetchHubListAsync(site: Site) : Promise<Hub[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/hubs`
    const response = await this.fetchWithToken(url);
    const responseObj : object[] = await response.json();
    const hubList = responseObj.map((h : any) => new Hub(this.dataContext, site, h.id, h.name) );
    return hubList;
  }


  async pairHubAsync(site: Site, hubId: string, pairingCode: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/hubs`
    const pairRequest = { hubId: hubId, pairingCode: pairingCode };
    const _response = await this.postDataWithToken(url, pairRequest);
    return new Hub(this.dataContext, site, hubId, "New Hub");
  }

  async patchHubAsync(hub: Hub, name: string) : Promise<number> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${hub.site.account.accountId}/sites/${hub.site.siteId}/hubs/${hub.hubId}`
    const patchData = { name: name};
    const response = await this.patchDataWithToken(url, patchData);
    return response.status;
  }


  async unpairHubAsync(hub: Hub) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${hub.site.account.accountId}/sites/${hub.site.siteId}/hubs/${hub.hubId}`
    const _response = await this.deleteWithToken(url);
    return;
  }

  async restartHubAsync(hub: Hub) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${hub.site.account.accountId}/sites/${hub.site.siteId}/hubs/${hub.hubId}/restart`
    const _response = await this.postWithToken(url);
    return;
  }

  async putHubRecordingRequestAsync(hub: Hub, recordingRequest: HubRecordingRequest) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${hub.site.account.accountId}/sites/${hub.site.siteId}/hubs/${hub.hubId}/recording`
    const _response = await this.putDataWithToken(url, recordingRequest);
    return;
  }

  async getHubStatus(accountId: string, siteId: string, hubId: string, live: boolean): Promise<HubStatusResponse | null> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/hubs/${hubId}/status` + (live ? '?live' : '');
    const response = await this.fetchWithToken(url);
    if (response.status !== 200)
      return null;
    const responseObj = await response.json();
    try {
      return new HubStatusResponse(hubId, responseObj);
    } catch (error) {
      return null;
    }
  }
  //-------------
  // Cameras

  async fetchCameraListAsync(site: Site) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/cameras`
    const response = await this.fetchWithToken(url);
    const responseObj : object[] = await response.json();
    responseObj.sort((a: any, b: any) => (a.name && b.name) ? a.name.localeCompare(b.name) : a.serialNumber.localeCompare(b.serialNumber));   
    const cameraList = responseObj.map((c : any, index: number) => 
              new Camera(this.dataContext, site, c.id, c.name, c.cameraType,
                c.scanAreaId, c.hubId, c.address, c.manufacturer, c.model, c.serialNumber,
                c.onvifChannel, c.onvifSubStream, c.autoRtspUrl, c.rtspUrl, 
                c.fov ?? 91.25, c.width ?? 2960, c.height ?? 1668, c.distortionCoeff ?? [-0.1675, 0.0, 0.0, 0.0, 0.0],
                c.color ?? cameraColors(index), index));
    return cameraList;
  }
  
  

  async updateCameraAsync(camera: Camera) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${camera.site.account.accountId}/sites/${camera.site.siteId}/cameras/${camera.cameraId}`
    const _response = await this.putDataWithToken(url, 
      {
        id: camera.cameraId,
        accountId: camera.site.account.accountId,
        siteId: camera.site.siteId,
        scanAreaId: camera.scanAreaId,
        name: camera.cameraName,
        cameraType: camera.cameraType,
        hubId: camera.hubId,
        manufacturer: camera.manufacturer,
        model: camera.model,
        serialNumber: camera.serialNumber,
        address: camera.address,
        autoRtspUrl: camera.autoRtspUrl,
        rtspUrl: camera.rtspUrl,
        onvifChannel: camera.onvifChannel,
        onvifSubStream: camera.onvifSubStream,
        fov: camera.fov
      });
    return;
  }

  async deleteCameraAsync(camera: Camera) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${camera.site.account.accountId}/sites/${camera.site.siteId}/cameras/${camera.cameraId}`
    const _response = await this.deleteWithToken(url);
    return;
  }

  async createCameraAsync(site: Site, camera: Camera) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/cameras`
    const cameraData = {
      accountId: camera.site.account.accountId,
      siteId: camera.site.siteId,
      scanAreaId: camera.scanAreaId,
      name: camera.cameraName,
      cameraType: camera.cameraType,
      hubId: camera.hubId,
      manufacturer: camera.manufacturer,
      model: camera.model,
      serialNumber: camera.serialNumber,
      address: camera.address,
      rtspUrl: camera.rtspUrl,
      onvifChannel: camera.onvifChannel,
      onvifSubStream: camera.onvifSubStream,
      fov: camera.fov
    };
    const response = await this.postDataWithToken(url, cameraData);
    const responseObj = await response.json();
    camera.cameraId = responseObj.id;
    return camera;
  }  

  async fetchIntegrationsListAsync(accountId: string, siteId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/integrations`
    const response = await this.fetchWithToken(url);
    const responseObj : object[] = await response.json();
    const integrationsList = responseObj.map((i : any) => new SiteIntegration(i.id, i.accountId, i.siteId, i.service, i.details) );
    return integrationsList;
  }

  async fetchCameraImageUrl(accountId: string, siteId: string, cameraId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/cameras/${cameraId}/snapshotsasurl`
    const response = await this.fetchWithToken(url);
    if (response.status === 200) {
      const imageUrl = await response.text();
      return imageUrl;
    }
    return "";
  }

  //---------------
  // Sites

  async fetchSiteListAsync(account: Account) : Promise<Site[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${account.accountId}/sites`
    const response = await this.fetchWithToken(url);
    if (response.status === 404) {
      return [];
    }
    const responseObj : object[] = await response.json();
    const siteList = responseObj.map((s : any) => new Site(this.dataContext, account, s.id, s.siteName, s.timeZone, s.openHours, s.dayOfWeekStart, s.address, s.siteRoles, s.siteCapabilities));
    return siteList;
  }
  
  async fetchSiteAsync(account: Account, siteId: string) : Promise<Site | null> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${account.accountId}/sites/${siteId}`
    const response = await this.fetchWithToken(url);
    if (response.status === 404){
      return null;
    }
    const responseObj : any = await response.json();
    const site = new Site(new DataContext(this), account, responseObj.id, responseObj.siteName, responseObj.timeZone, responseObj.openHours, responseObj.dayOfWeekStart, responseObj.address, responseObj.siteRoles, responseObj.siteCapabilities);
    return site;
  }

  async deleteSiteAsync(accountId: string, siteId: string) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}`
    await this.deleteWithToken(url);
    return;
  }

  async createSiteAsync(account: Account, name: string, timeZone: string, openHours: number[], dayOfWeekStart: number, address: Address) : Promise<Site> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${account.accountId}/sites`
    const data = { siteName: name, address: address, timeZone: timeZone, openHours: openHours, dayOfWeekStart: dayOfWeekStart};
    const response = await this.postDataWithToken(url, data);
    const responseObj : any = await response.json();
    const site = new Site(
      new DataContext(this), 
      account, responseObj.id, 
      responseObj.siteName,
      responseObj.timeZone,
      responseObj.openHours,
      responseObj.dayOfWeekStart,
      responseObj.address, 
      responseObj.siteRoles, 
      responseObj.siteCapabilities
    );
    return site;
  }

  async createShadowSiteAsync(sourceSite: Site, destAccount: Account, destSiteName: string) : Promise<Site> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${destAccount.accountId}/shadowsites`
    const data = { destSiteName: destSiteName, sourceAccountId: sourceSite.account.accountId, sourceSiteId: sourceSite.siteId};
    const response = await this.postDataWithToken(url, data);
    const responseObj : any = await response.json();
    const shadowSite = new Site(
      new DataContext(this), 
      destAccount, responseObj.id, 
      responseObj.siteName,
      responseObj.timeZone,
      responseObj.openHours,
      responseObj.dayOfWeekStart,
      responseObj.address, 
      responseObj.siteRoles, 
      responseObj.siteCapabilities
    );
    return shadowSite;
  }

  async updateSiteAsync(accountId: string, siteId: string, name: string, timeZone: string, openHours: number[], dayOfWeekStart: number, address: Address) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}`
    const data = { siteName: name, address: address, timeZone: timeZone, openHours: openHours, dayOfWeekStart: dayOfWeekStart};
    await this.putDataWithToken(url, data);
    return;
  }

  //----------
  // Scans

  async fetchScanListAsync(scanArea: ScanArea) : Promise<Scan[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${scanArea.site.account.accountId}/sites/${scanArea.site.siteId}/scanareas/${scanArea.scanAreaId}/scans`
    const response = await this.fetchWithToken(url);
    const responseObj : object[] = await response.json();
    const scanAreaList = responseObj.map((s : any) => new Scan(this.dataContext, scanArea, s.id, s.scanVersion, new Date(s.scanTime), s.scanProcessState) );
    return scanAreaList;
  }
  
  async fetchScanAreaListAsync(site: Site) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/scanareas`
    const response = await this.fetchWithToken(url);
    const responseObj : object[] = await response.json();
    const scanAreaList = responseObj.map((sa : any) => new ScanArea(this.dataContext, site, sa.id, sa.scanAreaName));
    return scanAreaList;
  }

  async fetchScanAreaLayoutAsync(scan: Scan) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${scan.area.site.account.accountId}/sites/${scan.area.site.siteId}/scanareas/${scan.area.scanAreaId}/scans/${scan.scanId}/layout`
    const response = await this.fetchWithToken(url);
    const json : object = await response.json();
    const jsonCamelCase : any = convertPascalToCamelCase(json);
    const jsonDecorated = addModelTypeAndId(jsonCamelCase, "SiteBionics/ScanAreaLayout");
    const scanAreaLayout = fromSnapshot<ScanAreaLayout>(jsonDecorated);
    //const snapshot = getSnapshot<ScanAreaLayout>(scanAreaLayout);
    //const scanAreaLayout2 = fromSnapshot<ScanAreaLayout>(snapshot);
    return scanAreaLayout;
  }

  async saveScanAreaLayoutAsync(scanAreaLayout: ScanAreaLayout) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${scanAreaLayout.accountId}/sites/${scanAreaLayout.siteId}/scanareas/${scanAreaLayout.scanAreaId}/scans/${scanAreaLayout.scanId}/layout`
    const snapshot = getSnapshot<ScanAreaLayout>(scanAreaLayout);
    const jsonUnDecorated = removeModelType(snapshot);
    const jsonPascalCase : any = convertCamelToPascalCase(jsonUnDecorated);
    const response = await this.putDataWithToken(url, jsonPascalCase);
    return;
  }

  async fetchAvailbleModelTypes(scan: Scan) : Promise<ModelType[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${scan.area.site.account.accountId}/sites/${scan.area.site.siteId}/scanareas/${scan.area.scanAreaId}/scans/${scan.scanId}/models`
    const response = await this.fetchWithToken(url);
    const availableModelStrings : string[] = await response.json();
    const availableModels = availableModelStrings.map((availableModelName : string) => ModelType[availableModelName as keyof typeof ModelType]);
    return availableModels;
  }

  //------------
  // Track data
  async fetchTrackedObjectWithTrackletDataAsync(site: Site, startTime: Dayjs, endTime: Dayjs) : Promise<[TrackedObject[], Tracklet[]]> {
    
    const [trackedOjects, trackletMap] = await this.fetchTrackedObjectsAsync(site, startTime, endTime);
    
    //await this.fetchTrackletDataAsync(site, startTime, endTime, trackletMap);
    await this.fetchTrackletPositionsAsync(site, startTime, endTime, trackletMap);
    let trackletsWithDetections = 0;
    let trackletsWithNoDetections = 0;

    trackedOjects.forEach(trackedObject => {
      trackedObject.tracklets.forEach(tracklet => {
        if (tracklet.dataPoints.length === 0) {
          trackletsWithNoDetections++;
        }
        else
        {
          trackletsWithDetections++;
        }
        tracklet.dataPoints.sort((a, b) => a.time.getTime() - b.time.getTime());
      });
    });
    console.log(`Tracklets with detections: ${trackletsWithDetections}, Tracklets with no detections: ${trackletsWithNoDetections}`);

    return [trackedOjects, Object.values(trackletMap)]
  }

  async fetchTrackedObjectsAsync(site: Site, startTime: Dayjs, endTime: Dayjs) : Promise<[TrackedObject[], Record<string, Tracklet>]> {
    let cameras = await site.loadCamerasAsync();
    
    // load tracked object info
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/trackedobjects?startTime=${startTime.utc()}&endTime=${endTime.utc()}`
    const response = await this.fetchWithToken(url);
    const json = await response.text()
    const objects = JSON.parse(json);
    
    // map it into tracked objects and tracklets
    const trackletMap : Record<string, Tracklet> = {};
    const trackedObjectMap : Record<string, TrackedObject> = {};
    const trackedObjects : TrackedObject[] = objects.filter((obj: any) => !obj.isDeleted).map((obj: any, index: number) => {
     
        const tracklets : Tracklet[] = obj.tracklets.map((t: any) => {
              var camera = cameras?.find(c => c.cameraId === t.sensorId);
              var tracklet = new Tracklet(t.id, t.trackletType, t.objectId, new Date(t.startTime), new Date(t.endTime), t.isStationary, t.startsAtEntrance, t.endsAtEntrance, camera);
              trackletMap[tracklet.trackletId] = tracklet;
              return tracklet;
            });
        
        const timeEngagedWithTags: TimeSpanDictionary = {}
        if (obj.timeEngagedWithTags) {
          Object.keys(obj.timeEngagedWithTags).forEach((key) => {
            //console.log(key);
            let seconds = parseTimeSpan(obj.timeEngagedWithTags[key]);
            timeEngagedWithTags[key] = seconds;
          });
        }
        
        const trackedObject : TrackedObject = new TrackedObject(site, obj.id, obj.objectType, new Date(obj.startTime), new Date(obj.endTime), obj.isStationary, integerToColor(index), tracklets, timeEngagedWithTags);
        // if (trackedObject.trackedObjectId === "48844f3b-d3d8-4f8c-a456-d5cb840982d4") {
        //   console.log("Found tracked object");
        // }
          
        trackedObjectMap[trackedObject.trackedObjectId] = trackedObject;
        return trackedObject;
    });

    await this.fetchTrackedObjectEventsAsync(site, startTime, endTime, trackedObjectMap).catch((error) => {/*todo: handle error*/});

    await this.fetchTrackedObjectPositionsAsync(site, startTime, endTime, trackedObjectMap);
    
    return [trackedObjects, trackletMap];
  }

  async fetchTrackedObjectPositionsAsync(site: Site, startTime: Dayjs, endTime: Dayjs,  trackedObjectMap: Record<string, TrackedObject>) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/trackedobjectpositions?startTime=${startTime.utc()}&endTime=${endTime.utc()}`
    const response = await this.fetchWithToken(url);
    const json = await response.text()
    const buckets = JSON.parse(json);
    TrackedObject.AddTrackedObjectDataPointsFromBuckets(buckets, trackedObjectMap);
  }

  async fetchTrackletPositionsAsync(site: Site, startTime: Dayjs, endTime: Dayjs,  trackletMap: Record<string, Tracklet>) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/trackletpositions?startTime=${startTime.utc()}&endTime=${endTime.utc()}`
    const response = await this.fetchWithToken(url);
    const json = await response.text()
    const buckets = JSON.parse(json);
    Tracklet.AddTrackletDataPointsFromBuckets(buckets, trackletMap);
  }

  async fetchTrackletDataAsync(site: Site, startTime: Dayjs, endTime: Dayjs, trackletMap: Record<string, Tracklet>) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/trackletdata?startTime=${startTime.utc()}&endTime=${endTime.utc()}`
    const response = await this.fetchWithToken(url);
    const reader = response.body!.getReader();
    const decoder = new TextDecoder('utf-8');

    let buffer = '';
    try {
        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            buffer += decoder.decode(value, { stream: true });

            // Split the buffer into lines, retaining incomplete line in the buffer
            const lines = buffer.split('\n');
            buffer = lines.pop() || ''; 

            // Process each complete line
            lines.forEach(line => {
                if (line.trim() !== '') {
                    const dataPoint = TrackletDataPoint.FromCsv(line);
                    const tracklet = trackletMap[dataPoint.trackletId];
                    if (tracklet !== undefined) {
                      tracklet.dataPoints.push(dataPoint);
                    }
                }
            });
        }
    } finally {
        reader.releaseLock();
    }

    // Process any remaining text in the buffer
    if (buffer.trim() !== '') {
      const dataPoint = TrackletDataPoint.FromCsv(buffer);
      const tracklet = trackletMap[dataPoint.trackletId];
      if (tracklet !== undefined) { tracklet.dataPoints.push(dataPoint); }
    }
  }

  async fetchTrackedObjectEventsAsync(site: Site, startTime: Dayjs, endTime: Dayjs, trackedObjectMap?: Record<string, TrackedObject>) : Promise<TrackedObjectEvent[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/events?startTime=${startTime.utc()}&endTime=${endTime.utc()}`
    const response = await this.fetchWithToken(url);
    const json = await response.text()
    const objects = JSON.parse(json);

    const trackedObjectEvents : TrackedObjectEvent[] = objects.map((obj: any, index: number) => {
          
        var trackedObject = trackedObjectMap ? trackedObjectMap[obj.trackedObjectId] : undefined;
          var startTime = dayjs(obj.startTime)
          var endTime = dayjs(obj.endTime)
          var trackedObjectEvent = new TrackedObjectEvent(obj.id, obj.eventType, startTime.toDate(), endTime.toDate(), obj.zone, trackedObject);
          return trackedObjectEvent;
    });
    return trackedObjectEvents;
  }

  async fetchHeatmapDataAsync(site: Site, startTime: Dayjs, endTime: Dayjs) : Promise<HeatmapEntry[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/heatmap?startTime=${startTime.utc()}&endTime=${endTime.utc()}`
    const response = await this.fetchWithToken(url);
    const json = await response.text()
    const objects = JSON.parse(json);

    const heatmapEntries : HeatmapEntry[] = objects.map((obj: any, _index: number) => {
      var heatmapCellEntries = obj.heatmapCellEntries.map((c: any) => {
        return new HeatmapCellEntry(c.cellRow, c.cellCol, c.weight);
      });
      var heatmapEntry = new HeatmapEntry(obj.id, obj.accountId, obj.siteId,
          obj.trackedObjectId, obj.startTime, obj.endTime, obj.time, obj.tags, heatmapCellEntries);
        return heatmapEntry;
    });
    return heatmapEntries;
  }

  async fetchTaggedObjectCountsAsync(site: Site, startTime: Dayjs, endTime: Dayjs, interval: string, tag: string) : Promise<TaggedObjectCounts[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/taggedobjectcounts?startTime=${startTime.utc()}&endTime=${endTime.utc()}&interval=${interval}&tag=${tag}`
    const response = await this.fetchWithToken(url);
    const json = await response.text()
    const objects = JSON.parse(json);

    const taggedObjectCounts : TaggedObjectCounts[] = objects.map((obj: any, index: number) => {
          return new TaggedObjectCounts(obj.tag, obj.timeRange.startTime, obj.min, obj.max, obj.avg);
    });
    return taggedObjectCounts;
  }

  async fetchDwellTimeSummariesAsync(site: Site, startTime: Dayjs, endTime: Dayjs, minThresholdSeconds: number): Promise<DwellTimeSummary[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/dwell/summaries?startTime=${startTime}&endTime=${endTime}&minThresholdSeconds=${minThresholdSeconds}`;
    const response = await this.fetchWithToken(url);
    const json = await response.text();
    const objects = JSON.parse(json);

    const dwellTimeSummaries: DwellTimeSummary[] = objects.map((obj: any, index: number) => {
      return new DwellTimeSummary(obj.regionId, obj.totalDwellTime, obj.averageDwellTime);
    });

    return dwellTimeSummaries;
  }

  async fetchDwellTimesBucketsAsync(site: Site, startTime: Dayjs, endTime: Dayjs, regionId: string, bucketSizeSeconds: number): Promise<DwellTimeBucket[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/dwell/buckets?startTime=${startTime}&endTime=${endTime}&regionId=${regionId}&bucketSizeSeconds=${bucketSizeSeconds}`;
    const response = await this.fetchWithToken(url);
    const json = await response.text();
    const objects = JSON.parse(json);

    const dwellTimes: DwellTimeBucket[] = objects.map((obj: any, index: number) =>{
      return new DwellTimeBucket(obj.startInterval, obj.endInterval, obj.count);
    });

    return dwellTimes;
  }

  async fetchDwellTimesDetailsAsync(site: Site, startTime: Dayjs, minThresholdSeconds: number): Promise<DwellTimeDetail[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/dwell/details?startTime=${startTime}&minThresholdSeconds=${minThresholdSeconds}`;
    const response = await this.fetchWithToken(url);
    const json = await response.text();
    const objects = JSON.parse(json);

    const dwellTimes: DwellTimeDetail[] = objects.map((obj: any, index: number) => {
      return new DwellTimeDetail(obj.regionId, obj.date, obj.totalDwellTime, obj.engagedCount, obj.averageDwellTime);
    });

    return dwellTimes;
  }

  async fetchDwellTimesTotalsAsync(site: Site, startTime: Dayjs, minThresholdSeconds: number): Promise<DwellTimeTotal[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/dwell/totals?startTime=${startTime}&minThresholdSeconds=${minThresholdSeconds}`;
    const response = await this.fetchWithToken(url);
    const json = await response.text();
    const objects = JSON.parse(json);

    const dwellTimes: DwellTimeTotal[] = objects.map((obj: any, index: number) => {
      return new DwellTimeTotal(obj.regionId, obj.eventDate, obj.totalDwellTime);
    });

    return dwellTimes;
  }
  

  async fetchSiteEventsAsync(site: Site, startTime: Dayjs, endTime: Dayjs, eventType: string): Promise<SiteEvent[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/siteevents?startTime=${startTime}&endTime=${endTime}&eventType=${eventType}`;
    const response = await this.fetchWithToken(url);
    const json = await response.text();
    const objects = JSON.parse(json);

    const siteEvents: SiteEvent[] = objects.map((obj: any, index: number) => {

      return new SiteEvent(obj.eventDate, obj.eventType, obj.eventId, obj.eventItems, obj.additionalProperties);
    });
    return siteEvents;
  }

  async updateSiteEventsAsync(site: Site, siteEventUpdates: SiteEventUpdate[], timezone?: string): Promise<number> {
    let url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/siteevents`;
    if (timezone) {
      url = url + `?timezone=${timezone}`;
    }
    const response = await this.postDataWithToken(url, siteEventUpdates);
    const result = await response.text();
    return parseInt(result);
  }

  async fetchShiftsAsync(site: Site, startTime: Dayjs, endTime: Dayjs): Promise<Shift[]> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/shifts?startTime=${startTime}&endTime=${endTime}`;
    const response = await this.fetchWithToken(url);
    const json = await response.text();
    const objects = JSON.parse(json);

    const shifts: Shift[] = objects.map((obj: any, index: number) => {
      return new Shift(obj.firstName, obj.lastName, obj.employeeId, obj.startTime, obj.endTime);
    });
    return shifts;
  }

  async updateShiftsAsync(site: Site, shifts: ShiftUpdate[], timezone?: string): Promise<number> {
    let url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/shifts`;
    if (timezone) {
      url = url + `?timezone=${timezone}`;
    }
    const response = await this.postDataWithToken(url, shifts);
    const result = await response.text();
    return parseInt(result);
  }

  async fetchSiteResourceSasUri(accountId: string, siteId: string, resourceName: string) {
    const url = `${SiteBionicsService.serverRoot}/api/GetSiteResourceSasUri?AccountId=${accountId}&SiteId=${siteId}&resourceName=${resourceName}`
    const response = await this.fetchWithToken(url);
    const sasUri = await response.text();
    return sasUri;
  }

  async fetchSiteResourceAsync(accountId: string, siteId: string, resourceName: string) {
    const url = await this.fetchSiteResourceSasUri(accountId, siteId, resourceName);
    const response = await fetch(url);
    const obj = await response.json();
    return obj;
  }

  async fetchCameraMaskSasUri(camera: Camera) {
    const resourceName = `CameraMasks/${camera.cameraId}-mask.png`;
    const sasUri = await this.fetchSiteResourceSasUri(camera.site.account.accountId, camera.site.siteId, resourceName);
    return sasUri;
  }
  
  async fetchCameraSnapShotSasUri(camera: Camera) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${camera.site.account.accountId}/sites/${camera.site.siteId}/cameras/${camera.cameraId}/snapshotsasurl`
    const response = await this.fetchWithToken(url);
    const sasUri = await response.text();
    return sasUri;
  }
  
  async fetchTrackingStateAsync(site: Site) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${site.account.accountId}/sites/${site.siteId}/trackingstate`
    const response = await this.fetchWithToken(url);
    const obj = await response.json();
    return obj;
  }
  
  async fetchCameraSnapShots(camera: Camera, startTime: Dayjs, endTime: Dayjs) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${camera.site.account.accountId}/sites/${camera.site.siteId}/cameras/${camera.cameraId}/snapshots?after=${startTime}&before=${endTime}&count=100`
    const response = await this.fetchWithToken(url);
    const snapshots = await response.json();
    return snapshots;
  }

  async uploadSiteResourceAsync(accountId: string, siteId: string, resourceName: string, data: object) {
    const url = `${SiteBionicsService.serverRoot}/api/UploadSiteResource?AccountId=${accountId}&SiteId=${siteId}&resourceName=${resourceName}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  }

  dayjsToTicks(dayjsDate: Dayjs) {
    // Unix time in milliseconds
    const unixTimeMs = dayjsDate.valueOf();
    // Convert milliseconds to ticks (1 ms = 10,000 ticks)
    const ticksSinceEpoch = unixTimeMs * 10000;
    // Ticks between C# DateTime epoch and Unix epoch
    const epochDifferenceTicks = 621355968000000000;
    // Total ticks in C# DateTime
    return ticksSinceEpoch + epochDifferenceTicks;
  }

  async postSiteUploadVideoRequest(accountId: string, siteId: string, priority: FragmentUploadPriority, startTime: Dayjs, endTime: Dayjs, fragmentType : number = 0) : Promise<Dayjs | undefined> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/video/request?starttime=${this.dayjsToTicks(startTime)}&endtime=${this.dayjsToTicks(endTime)}&fragmentType=${fragmentType}&priority=${priority}`
    const response = await this.postWithToken(url);
    const actualStartTime = await response.text();
    return actualStartTime ? dayjs(actualStartTime.replace(/['"]/g, '')) : undefined; // remove quotes before parsing
  }

  async postSiteCancelSystemUploadVideoRequest(accountId: string, siteId: string) : Promise<void> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/video/cancelsystemrequests`
    await this.postWithToken(url);
  }
  
  async postUploadVideoRequest(accountId: string, siteId: string, cameraId: string, priority: FragmentUploadPriority, startTime: Dayjs, endTime: Dayjs, fragmentType : number = 0) : Promise<Dayjs | undefined> {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/cameras/${cameraId}/video/request?starttime=${this.dayjsToTicks(startTime)}&endtime=${this.dayjsToTicks(endTime)}&fragmentType=${fragmentType}&priority=${priority}`
    const response = await this.postWithToken(url);
    const actualStartTime = await response.text();
    return actualStartTime ? dayjs(actualStartTime.replace(/['"]/g, '')) : undefined; // remove quotes before parsing
  }

  async fetchVideoPlayList(accountId: string, siteId: string, cameraId: string, startTime: Dayjs, endTime: Dayjs) {
    const url = await this.videoPlayListUrl(accountId, siteId, cameraId, startTime, endTime);
    const response = await this.fetchWithToken(url);
    const playList = await response.text();
    return playList;
  }

  async videoPlayListUrl(accountId: string, siteId: string, cameraId: string, startTime: Dayjs, endTime: Dayjs) {
    let qs = "";
    if (this._defaultTokenType === "Bearer") {
      qs = "bt=" + await this.getMsalToken();
    } else {
      qs = "sbjwt=" + await this.getAccessToken();
    }
    
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/cameras/${cameraId}/video.m3u8?starttime=${this.dayjsToTicks(startTime)}&endtime=${this.dayjsToTicks(endTime)}&requesttime=${dayjs().utc().toISOString()}&${qs}`

    return url;
  }
  
  async fetchAvailableVideo(accountId: string, siteId: string, startTime: Dayjs, endTime: Dayjs) {
    const url = `${SiteBionicsService.serverRoot}/api/accounts/${accountId}/sites/${siteId}/availablevideo?starttime=${this.dayjsToTicks(startTime)}&endtime=${this.dayjsToTicks(endTime)}`
    const response = await this.fetchWithToken(url);
    const availableVideo = await response.json();
    return availableVideo;
  }
}

