import { ExtendedModel, model, modelAction, prop } from "mobx-keystone";
import LayoutEntity from "./LayoutEntity";
import CornerEntity from "./CornerEntity";
import DoorEntity from "./DoorEntity";
import WindowEntity from "./WindowEntity";
import ScanAreaLayoutViewModel, { useScanAreaLayoutViewModel } from "./ScanAreaLayoutViewModel";
import { action } from "mobx";

export const findNextAvailableWallName = (layoutViewModel: ScanAreaLayoutViewModel) => {
    let wallIdSet = new Set(layoutViewModel.scanAreaLayout.walls.map(wall => wall.name));
    let newWallName = "New Wall";
    for (let i = 1; i <= wallIdSet.size + 1; i++) {
      if (!wallIdSet.has("Wall" + i)) {
        newWallName = "Wall" + i;
        break;
      }
    }
    return newWallName;
}

@model("SiteBionics/WallEntity")
export default class WallEntity extends ExtendedModel(LayoutEntity, {
    name: prop<string>().withSetter(),
    startCorner: prop<CornerEntity>().withSetter(),
    endCorner: prop<CornerEntity>().withSetter(),
    doors: prop<Array<DoorEntity>>().withSetter(),
    windows: prop<Array<WindowEntity>>().withSetter(),
}) {

    @modelAction addDoorToWall(doorToAdd: DoorEntity) {
        this.doors.push(doorToAdd);
    }

    @modelAction removeDoorFromWall(doorToRemove: DoorEntity) {
      if (this.doors.includes(doorToRemove)) {
        this.doors = this.doors.filter((door) => door !== doorToRemove);
      }
    }

    @modelAction addWindowToWall(windowToAdd: WindowEntity) {
        this.windows.push(windowToAdd);
    }

    @modelAction removeWindowFromWall(windowToRemove: WindowEntity) {
      if (this.windows.includes(windowToRemove)) {
        this.windows = this.windows.filter((window) => window !== windowToRemove);
      }
    }
}