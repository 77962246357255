// specialDays.ts
export type SpecialDayType = "Holiday" | "Retail" | "Business";

export interface SpecialDayData {
  name: string;
  type: SpecialDayType;
  month: number;
  day?: number;
  occurrence?: number;
  weekday?: number;
}

export class SpecialDay {
  name: string;
  type: SpecialDayType;
  month: number;
  day?: number;
  occurrence?: number;
  weekday?: number;

  constructor({ name, type, month, day, occurrence, weekday }: SpecialDayData) {
    this.name = name;
    this.type = type;
    this.month = month;
    this.day = day;
    this.occurrence = occurrence;
    this.weekday = weekday;
  }

  matches(date: Date): boolean {
    if (date.getMonth() + 1 !== this.month) return false;

    if (this.day !== undefined) {
      return date.getDate() === this.day;
    } else if (this.occurrence !== undefined && this.weekday !== undefined) {
      if (this.name === "Super Saturday") {
        return this.isSuperSaturday(date);
      }
      return date.getDate() === SpecialDay.getNthWeekdayOfMonth(date.getFullYear(), this.month, this.weekday, this.occurrence);
    }

    return false;
  }

  private isSuperSaturday(date: Date): boolean {
    const year = date.getFullYear();
    const christmas = new Date(year, 11, 25); // December 25th
    const dayOfWeek = christmas.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate the last Saturday before Christmas
    const daysToSubtract = dayOfWeek + 1;
    const lastSaturdayBeforeChristmas = new Date(christmas);
    lastSaturdayBeforeChristmas.setDate(christmas.getDate() - daysToSubtract);

    return date.getTime() === lastSaturdayBeforeChristmas.getTime();
  }

  static getNthWeekdayOfMonth(year: number, month: number, weekday: number, occurrence: number): number {
    const firstDay = new Date(year, month - 1, 1);
    let firstWeekday = firstDay.getDay();
    if (firstWeekday === 0) firstWeekday = 7; // Convert Sunday to 7

    let day = 1 + ((weekday - firstWeekday + 7) % 7);

    if (occurrence > 0) {
      day += (occurrence - 1) * 7;
    } else {
      const lastDay = new Date(year, month, 0).getDate();
      let lastDate = new Date(year, month - 1, lastDay);
      let lastWeekday = lastDate.getDay();
      if (lastWeekday === 0) lastWeekday = 7;

      day = lastDay - ((lastWeekday - weekday + 7) % 7);
    }

    return day;
  }
}

export class SpecialDayChecker {
  private specialDays: SpecialDay[];

  constructor() {
    this.specialDays = specialDaysData.map(data => new SpecialDay(data));
  }

  getSpecialDay(date: Date): SpecialDay | null {
    return this.specialDays.find(s => s.matches(date)) || null;
  }
}

// Inline JSON Data
const specialDaysData: SpecialDayData[] = [
  { name: "New Year's Day", type: "Holiday", month: 1, day: 1 },
  { name: "Martin Luther King Jr. Day", type: "Holiday", month: 1, occurrence: 3, weekday: 1 },
  { name: "Presidents' Day", type: "Holiday", month: 2, occurrence: 3, weekday: 1 },
  { name: "Memorial Day", type: "Holiday", month: 5, occurrence: -1, weekday: 1 },
  { name: "Independence Day", type: "Holiday", month: 7, day: 4 },
  { name: "Labor Day", type: "Holiday", month: 9, occurrence: 1, weekday: 1 },
  { name: "Columbus Day / Indigenous Peoples' Day", type: "Holiday", month: 10, occurrence: 2, weekday: 1 },
  { name: "Veterans Day", type: "Holiday", month: 11, day: 11 },
  { name: "Thanksgiving Day", type: "Holiday", month: 11, occurrence: 4, weekday: 4 },
  { name: "Christmas Eve", type: "Holiday", month: 12, day: 24 },
  { name: "Christmas Day", type: "Holiday", month: 12, day: 25 },
  { name: "New Year's Eve Day", type: "Holiday", month: 12, day: 31 },
  { name: "Black Friday", type: "Retail", month: 11, occurrence: 4, weekday: 5 },
  { name: "Cyber Monday", type: "Retail", month: 11, occurrence: 4, weekday: 1 },
  { name: "Small Business Saturday", type: "Retail", month: 11, occurrence: 4, weekday: 6 },
  { name: "Super Saturday", type: "Retail", month: 12, occurrence: -1, weekday: 6 },
  { name: "Tax Day", type: "Business", month: 4, day: 15 },
  { name: "End of Q1", type: "Business", month: 3, day: 31 },
  { name: "End of Q2", type: "Business", month: 6, day: 30 },
  { name: "End of Q3", type: "Business", month: 9, day: 30 },
  { name: "End of Q4", type: "Business", month: 12, day: 31 }
];
